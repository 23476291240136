import _ from 'lodash';
import { backendStateToFrontendState, Transaction } from '.';
import { OrderSearchResult } from '../order';

export const transactionToEventModel = (tx: Transaction) => ({
  id: tx.id,
  status: tx.status,
  transactionStatus: tx.transactionStatus,
  order: {
    orderNumber: tx.order.orderNumber,
    fulfillmentOption:
      (tx.order.fulfillmentOption && {
        date: tx.order.fulfillmentOption?.date,
      }) ||
      null,
  },
});

export const toOrderSearchResult = (tx: Transaction): OrderSearchResult => ({
  id: tx.id,
  presaleId: tx.order?.presaleId,
  orderNumber: tx.order?.orderNumber,
  status: tx.status,
  frontendStatus: tx.frontendState(),
  stage: tx.order?.stage,
  transactionStatus: tx.transactionStatus,
  createdAt: tx.createdAt,
  customerName: `${tx.customerObj.firstName} ${tx.customerObj.lastName}`,
  type: tx.order?.type,
  fulfillmentOption: {
    id: tx.order?.fulfillmentOption?.id,
    type: tx.order?.fulfillmentOption?.type,
    displayName: tx.order?.fulfillmentOption?.displayName,
    date: tx.order?.fulfillmentOption.date,
  },
  total: tx.totals?.total,
  numItems: _.reduce(tx.order?.items, (x, i) => i.quantity + x, 0),
});


export const findDueDate = tx => {
  return (
    _.get(tx, 'order.fulfillmentOption.schedule.dates[0].startTime') ||
    tx.order.fulfillmentOption?.date ||
    tx.order.requestedFulfillment?.date
  );
};

export const transactionToESDoc = (tx, shop) => ({
  presaleId: tx.order?.presaleId,
  orderNumber: tx.order.orderNumber,
  createdAt: tx.createdAt,
  shop: {
    id: tx.shopId,
    businessName: shop.businessName,
  },
  customer: {
    id: tx.customerObj?.id,
    firstName: tx.customerObj?.firstName,
    lastName: tx.customerObj?.lastName,
    fullName: `${tx.customerObj?.firstName} ${tx.customerObj?.lastName}`,
    email: tx.customerObj?.email,
    mobileNumber: tx.customerObj?.mobileNumber,
    addressLine1: tx.customerObj?.addressOne,
    addressLine2: tx.customerObj?.addressTwo,
    city: tx.customerObj?.city,
    region: tx.customerObj?.state,
    postalCode: tx.customerObj?.postalCode,
    country: tx.customerObj?.country,
    isSubscriber: tx.customerObj?.subscribed,
    hasEverSubscribed: tx.customerObj?.hasEverSubscribed,
  },
  status: tx.status,
  type: tx.order.type,
  stage: tx.order.type === 'custom' && tx.transactionStatus !== 'succeeded' ? 'quote' : 'order',
  frontendStatus: backendStateToFrontendState(
    tx,
    tx.order.type === 'custom' && tx.transactionStatus !== 'succeeded' ? 'quote' : 'order',
  ),
  numItems: tx.order.items?.length,
  items: tx.order.items?.map(i => ({
    productId: i.id,
    title: i.title,
    type: i.type,
    unitPrice: i.price,
    quantity: i.quantity,
    total: i.total,
  })),
  requestedFulfillment: {
    type: tx.order.requestedFulfillment?.type,
    date: tx.order.requestedFulfillment?.date,
    ...(tx.order.requestedFulfillment?.address && { address: tx.order.requestedFulfillment?.address }),
  },
  shippingInfo: tx.shippingInfo,
  fulfillmentOption: _.pick(tx.order.fulfillmentOption, ['id', 'type', 'fee', 'date', 'address']),
  totals: tx.totals,
  transactionStatus: tx.transactionStatus,
  isGift: tx.isGift,
  customerNotes: tx.notes,
  artisanNotes: tx.artisanNotes,
  rejectionNotes: tx.rejectionNote,
  coupon: {
    id: tx.coupon?.id,
    code: tx.coupon?.code,
    discountType: tx.coupon?.discount.type,
    discountValue: tx.coupon?.discount.value,
  },
  attribution: tx.attribution,
  isArchived: tx.isArchived,
  dueAt: findDueDate(tx),
});

export type ESOrderDoc = ReturnType<typeof transactionToESDoc>;