import React from 'react';
import {FormikProps} from 'formik';
import {Button} from '../';


type Props = {
  isSubmitting: boolean;
  formikState?: FormikProps<any>;
  handleSubmit?: (e?: any) => void;
  fullWidth?: boolean;
  customName?: string;
  className?: string;
  onClick?: () => void;
};


const SaveButton: React.FC<Props> = (props: Props) => {
  const { className, formikState, isSubmitting, handleSubmit, fullWidth, customName, onClick } = props;


  return (
    <Button
      fullWidth={fullWidth}
      variant='contained'
      type='submit'
      color='primary'
      disabled={isSubmitting}
      onClick={() => {
        onClick && onClick();
        formikState ? formikState.submitForm() : handleSubmit()
      }}
      className={className}
    >
      {customName || 'Save'}
    </Button>
  );
};

export default SaveButton;
