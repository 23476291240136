import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import store from './store';
import {SessionConfiguration, Theme} from '@castiron/components';
import './index.css';
import App from './components/App/App';
import Error from './components/Error';
import {Configuration, FirebaseApp, FirebaseContext} from '@castiron/castiron-firebase';
import {createBrowserHistory} from "history";
import firebase from 'firebase/compat/app';
import {AttributionConfig} from "@castiron/utils";
import FeatureConfiguration from "./components/FeatureConfiguration";
import StripeElementsProvider from "./components/StripeElementsProvider";
import CelloReferralConfiguration from "./components/CelloReferralConfiguration";
import {sessionRepository} from "./domain";

const history = createBrowserHistory();

if (process.env.REACT_APP_IS_LOCAL !== 'false') {
  Sentry.init({
    dsn: 'https://7616a48270f84e1498d2664c14e90a08@o732523.ingest.sentry.io/5796066',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    autoSessionTracking: true,
    tracesSampleRate: 1,
    environment: process.env.REACT_APP_STAGE,
  });
}

if (process.env.REACT_APP_IS_LOCAL_FIREBASE == 'true') {
  console.warn('Local Environment Detected, pointing Firebase to local emulators.');
  FirebaseApp.functions().useEmulator('localhost', 5001);
  FirebaseApp.firestore().useEmulator('localhost', 6001);
  FirebaseApp.storage().useEmulator('localhost', 9199);
  FirebaseApp.auth().useEmulator('http://localhost:9099');
}

// @ts-ignore
const remoteConfig = firebase.remoteConfig(FirebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 15000;

const error = <Error/>;

const urlParams = new URLSearchParams(window.location.search);

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={error} showDialog beforeCapture={(scope => {
    scope.setTags({WSOD: true});
  })}>
    <FirebaseContext.Provider value={FirebaseApp}>
      <AttributionConfig gaStreamId={process.env.REACT_APP_GA_MEASUREMENT_ID}>
        <SessionConfiguration sessionManager={{
          create: s => sessionRepository.create(s),
          update: s => sessionRepository.update(s),
          get: id => sessionRepository.get(id)
        }} sid={urlParams.get('sid')}>
          <Provider store={store}>
            <FeatureConfiguration>
              <Configuration remoteConfig={remoteConfig}>
                <CelloReferralConfiguration>
                  <StripeElementsProvider>
                    <Theme>
                      <Router>
                        <Switch>
                          <App/>
                        </Switch>
                      </Router>
                    </Theme>
                  </StripeElementsProvider>
                </CelloReferralConfiguration>
              </Configuration>
            </FeatureConfiguration>
          </Provider>
        </SessionConfiguration>
      </AttributionConfig>
    </FirebaseContext.Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
