import React, { ReactNode } from 'react';
import { Box, FormControl, Input as MuiInput, InputAdornment, InputLabel, TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, FormikErrors, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import Typography from '../Typography';

type Props = {
  autocomplete?: string;
  id?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  type?: string;
  label?: ReactNode;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number | string;
  select?: boolean;
  children?: ReactNode;
  priceInput?: boolean;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  errorText?: string;
  name?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  disabled?: boolean;
  containerClass?: string;
  phoneMask?: boolean;
  step?: number;
  autoFocus?: boolean;
  value?: any;
  className?: string;
  required?: boolean;
  style?: React.CSSProperties;
  touched?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  hideArrows?: boolean;
  opaque?: boolean;
  hasInputBackground?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  onChange?: (event: any) => void;
  onWheel?: (event: any) => void;
  onClick?: (event: any) => void;
  onKeyUp?: (event: any) => void;
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const PhoneMask = (props: any) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
};

const MaskedPhoneInput = (props: TextMaskCustomProps): any => {
  const { inputRef, ...other } = props;

  return (
    <FormControl>
      <MuiInput {...other} inputComponent={PhoneMask as any} />
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  fullWidthContainer: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  extraHeight: {
    height: 48,
  },
  inputBackground: {
    backgroundColor: theme.branding.v2.gray[0],
    marginBottom: 4,
    height: 15,
    borderRadius: 4,
  },
  label: {
    color: theme.palette.common.black,
    marginBottom: 16,
    fontSize: 14,
    fontWeight: 700,
  },
  opaque: {
    opacity: '100%',
    backgroundColor: theme.branding.gray[100],
  },
  adornment: {
    '& .MuiTypography-colorTextSecondary': {
      color: '#1f1f1f',
    },
  },
  adornmentDisabled: {
    '& .MuiTypography-colorTextSecondary': {
      color: theme.branding.v2.gray[400],
    },
  },
  noArrows: {
    '& > input::-webkit-outer-spin-button': {
      appearance: 'textfield',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& > input::-webkit-inner-spin-button': {
      appearance: 'textfield',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& > input[type="number"]': {
      appearance: 'textfield',
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,

      '&:hover::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        margin: 0,
      },
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
    '&:hover::-webkitinner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
    '&:hover::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
  },
  errorText: {
    color: theme.branding.red.primary,
  },
}));

// Formik-optimized input component
// @ts-ignore
const Input: React.FC<Props> = (props: Props) => {
  const {
    autocomplete,
    id,
    placeholder,
    min,
    max,
    disabled,
    onBlur,
    onFocus,
    containerClass,
    type,
    label,
    startAdornment,
    endAdornment,
    fullWidth,
    multiline,
    rows,
    name,
    select,
    children,
    priceInput,
    error,
    phoneMask,
    step,
    autoFocus,
    onChange,
    value,
    className,
    required,
    onWheel,
    style,
    touched,
    variant,
    hideArrows,
    opaque,
    onClick,
    onKeyUp,
    hasInputBackground,
  } = props;
  const classes = useStyles();
  const { handleBlur } = useFormikContext();

  const getStartAdornment = () => {
    if (priceInput)
      return (
        <InputAdornment className={disabled ? classes.adornmentDisabled : classes.adornment} position="start">
          $
        </InputAdornment>
      );
    if (startAdornment) return <InputAdornment position="start">{startAdornment}</InputAdornment>;
  };

  // Allows us to use custom onBlur function while still calling formik's handleBlur
  const blurHandler = e => {
    if (onBlur) {
      onBlur(e);
    }
    if (handleBlur) {
      handleBlur(e);
    }
  };

  return (
    <Box
      className={`${classes.container} ${fullWidth ? classes.fullWidthContainer : ''} ${containerClass}`}
      style={style}
    >
      {label ? (
        typeof label === 'string' ? (
          <InputLabel variant="standard" className={classes.label} required={required}>
            {label}
          </InputLabel>
        ) : (
          label
        )
      ) : null}
      <Field
        autoComplete={autocomplete}
        as={phoneMask ? MaskedPhoneInput : TextField}
        name={name}
        {...(value ? { value } : {})}
        error={!!error}
        type={type}
        helperText={error}
        size="small"
        multiline={multiline}
        margin="normal"
        rows={rows}
        disabled={disabled}
        fullWidth={fullWidth}
        required={required}
        InputProps={{
          classes: {
            root: `${className && className} ${!multiline && classes.extraHeight} ${hideArrows &&
              classes.noArrows} ${opaque && classes.opaque}`,
            input: hasInputBackground && classes.inputBackground,
          },
          inputProps: { min: min, max: max, step: step || 0.01 },
          startAdornment: (priceInput || startAdornment) && getStartAdornment(),
          endAdornment,
        }}
        variant={variant ? variant : 'outlined'}
        id={id}
        placeholder={placeholder}
        touched={touched}
        select={select}
        onBlur={blurHandler}
        autoFocus={autoFocus}
        {...(onChange ? { onChange } : {})}
        onWheel={onWheel}
        onClick={onClick}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
      >
        {children}
      </Field>
      {props.errorText && (
        <Typography variant="subtitle4" className={classes.errorText}>
          {props.errorText}
        </Typography>
      )}
    </Box>
  );
};

export default Input;
