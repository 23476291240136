import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import useVariant from '@castiron/components/src/Experiment/useVariant';
import { getProductsAction } from '../../store/reducers/products';
import { openModal } from '../../store/reducers/modalConductor';
import Stats from './Stats';
import { setRedirectUrl } from '../../store/reducers/shops';
import Spinner from '../Spinner';
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Menus/AvatarMenu';
import SubscriptionBanner from '../SubscriptionBanner';
import ViewShopButton from '../Layout/Header/ViewShopButton';
import { Banner, Button, Typography, useFeatures } from '@castiron/components';
import { ChecklistValues, backendStateToFrontendState } from '@castiron/domain';
import { getProductStatus, useTracking } from '@castiron/utils';
import { getService } from '../../firebase';
import { prepareQuoteSegmentData } from '../Quotes/QuoteUtils';
import { shopRepository } from '../../domain';
import firebase from 'firebase/compat/app';
import CastironUniversity from '../../assets/img/castiron-university.png';
import TheKitchen from '../../assets/img/the-kitchen.png';
import FeatureTooltip from '../FeatureTooltip';
import LaunchIcon from '@material-ui/icons/Launch';
import RedeemCouponBanner from '../RedeemCouponBanner';
import * as helpscout from '../../lib/helpscount';
import ReferralBlock from '../ReferralPrompts/ReferralBlock';
import PromoBlock, { showFirstMonthPromoBlock } from '../ReferralPrompts/PromoBlock';

const createCustomOrderService = getService('orders', 'createcustomorderv2');

const useStyles = makeStyles((theme: Theme) => ({
  actionTile: {
    backgroundColor: theme.branding.v2.gray[0],
    borderRadius: 12,
    color: theme.branding.v2.blue[500],
    height: 100,
    padding: 16,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    '&:hover': {
      border: `1px solid ${theme.branding.v2.gray[300]}`,
      cursor: 'pointer',
    },
  },
  actionTilesContainer: {
    gap: 8,
  },
  activeStatusPill: {
    backgroundColor: theme.branding.v2.green[50],
    color: theme.branding.v2.green[500],
  },
  arrow: {
    color: theme.branding.v2.blue[500],
    height: 20,
    width: 20,
  },
  arrowContainer: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    marginLeft: 16,
    padding: 10,
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
  },
  infoTile: {
    backgroundColor: theme.branding.v2.gray[50],
    borderRadius: 12,
    padding: '16px',
    textAlign: 'center',
  },
  infoTileButton: {
    marginTop: 'auto',
    padding: '12px 16px',
  },
  infoTilesContainer: {
    gap: 16,
    overflow: 'auto',
  },
  launchIcon: {
    height: 16,
    marginLeft: 8,
    width: 16,
  },
  noSalesBanner: {
    backgroundColor: theme.branding.v2.green[50],
    color: theme.branding.v2.green[500],
  },
  pill: {
    borderRadius: 100,
    padding: '4px 12px',
    width: 'fit-content',
  },
  prelaunchStatusPill: {
    backgroundColor: theme.branding.v2.yellow[50],
    color: theme.branding.v2.yellow[900],
  },
  setup: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    padding: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  setupNoCursor: {
    '&:hover': {
      cursor: 'auto',
    },
  },
  spinnerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toDoTile: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    gap: 8,
    padding: 16,
  },
  tooltip: {
    margin: 0,
  },
}));

export interface Props extends LayoutPageProps {
  popChat?: boolean;
}

const Dashboard: React.FC<Props> = props => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs, popChat } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showSpinner, setShowSpinner] = useState(false);
  const [isPublishingShop, setIsPublishingShop] = useState(false);

  const subscriptionBannerExperiment = useVariant('Subscription Upgrade Banner Test');
  const isVariant = subscriptionBannerExperiment?.name === 'Home Page';

  const {
    account,
    discount,
    isReady,
    shop,
    transactions,
    products,
    redirectUrl,
    isProductsLoading,
    isShopLoading,
    modal,
    stripeStatus,
  } = useAppSelector(state => ({
    account: state.shops.account,
    discount: state.shops.discount,
    isReady: state.shops.account?.isReady,
    shop: state.shops.shop,
    transactions: state.transactions.transactions,
    isTransactionsLoading: state.transactions.loading,
    isProductsLoading: state.products.loading,
    isShopLoading: state.shops.loading,
    products: state.products.products,
    redirectUrl: state.shops.redirectUrl,
    modal: state.modal,
    stripeStatus: state.shops.stripe.status,
  }));

  const shopStatusChecker =
    products?.filter(p => getProductStatus(p, shop?.config?.timeZone) === 'active')?.length > 0
      ? 'active'
      : shop.status;

  const openedOrderCount = transactions?.filter(tx => backendStateToFrontendState(tx, 'order') === 'open')?.length || 0;
  const newQuoteCount =
    transactions?.filter(tx => tx.order?.type === 'custom' && backendStateToFrontendState(tx, 'quote') === 'new')
      ?.length || 0;

  useEffect(() => {
    if (popChat) {
      helpscout.popChat();
    }
  }, [popChat]);

  const displaySeoLocationModal = () => {
    dispatch(
      openModal({
        modalType: 'SEO_LOCATION_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  useEffect(() => {
    // Check for redirect in query param
    if (shop && redirectUrl && history) {
      history.push(redirectUrl);
      dispatch(setRedirectUrl(''));
    }

    const seoLocationModal = localStorage.getItem('seenSeoLocationModal');
    if (
      !shop?.seoMetadata?.address?.addressLocality &&
      !shop?.seoMetadata?.address?.addressRegion &&
      !seoLocationModal
    ) {
      displaySeoLocationModal();
    }

    setPageTitle('Home');
    setFooterCTAs([]);

    window.scrollTo(0, 0);

    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    setHeaderCTAs([<ViewShopButton />, isMobile && <AvatarMenu />]);
  }, [isMobile]);

  useEffect(() => {
    if (shop && shop.id) {
      dispatch(getProductsAction(shop.id));
    }
    if (isReady && shop?.status === 'prelaunch') {
      publishShop();
    }
  }, [dispatch, isReady, shop]);

  const shopStatusPill = {
    active: (
      <Typography className={`${classes.pill} ${classes.activeStatusPill}`} variant="caption">
        Shop Status: Accepting Orders
      </Typography>
    ),
    prelaunch: (
      <Typography className={`${classes.pill} ${classes.prelaunchStatusPill}`} variant="caption">
        Shop Status: Not Published
      </Typography>
    ),
  };

  const openAvailabilityModal = () => {
    dispatch(
      openModal({
        modalType: 'AVAILABILITY_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const openShareLinksModal = () => {
    dispatch(
      openModal({
        modalType: 'SHARE_LINKS_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const createNewQuote = async () => {
    setShowSpinner(true);
    const body = {
      id: shop.id,
      status: 'proposed',
      origination: 'artisan',
    };
    try {
      const createCustomOrderResponse = await createCustomOrderService(body);
      console.debug('Create custom product order response: ', createCustomOrderResponse);
      const { id } = createCustomOrderResponse;
      setShowSpinner(false);
      trackEvent('Create Quote Clicked', {
        ...prepareQuoteSegmentData(createCustomOrderResponse),
      });
      history.push('/quotes/edit/' + id);
    } catch (e) {
      console.error('Error creating custom product order: ', e);
      trackEvent('Create Quote Failed', { errorMsg: e });
      setShowSpinner(false);
    }
  };

  const publishShop = async () => {
    setIsPublishingShop(true);
    await shopRepository?.updateProps(shop.id, {
      checklistCompletions: firebase.firestore.FieldValue.arrayUnion(ChecklistValues.GoLive),
      status: 'active',
    });

    setIsPublishingShop(false);

    dispatch(
      openModal({
        modalType: 'SHOP_LIVE_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const toDoTiles = [
    {
      text: 'Open Orders',
      count: openedOrderCount,
      ctaAction: () => {
        trackEvent('To Do Tile Clicked', { type: 'Orders' });
        history.push('/orders');
      },
    },
    {
      text: 'New Quotes',
      count: newQuoteCount,
      ctaAction: () => {
        trackEvent('To Do Tile Clicked', { type: 'Quotes' });
        history.push('/quotes');
      },
    },
  ];

  const actionTiles = [
    {
      icon: '🍪',
      text: 'Create a New Product',
      ctaAction: () => {
        history.push('/products?tab=Templates');
      },
    },
    {
      icon: '🎂',
      text: 'Create a New Order Form',
      ctaAction: () => {
        history.push('/order-forms?tab=Templates');
      },
    },
  ];

  const infoTiles = [
    {
      image: CastironUniversity,
      title: 'Castiron Resources',
      text: 'Get business advice for food entrepreneurs',
      ctaAction: () => {
        window.open('https://castiron.me/blog', '_blank');
      },
    },
    {
      image: TheKitchen,
      title: 'The Kitchen Community',
      text: 'Grow your business alongside entrepreneurs across the world',
      ctaAction: () => {
        window.open('https://www.facebook.com/groups/thekitchencommunity', '_blank');
      },
    },
  ];

  const wrapWithFeatureTooltip = (tile, children) => (
    <FeatureTooltip key={`feature-${tile.text}`} feature={tile.feature} featureName={tile.featureName}>
      {children}
    </FeatureTooltip>
  );

  const getActionTile = tile => {
    const content = (
      <Grid
        key={tile.text}
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        direction="column"
        justify="center"
        className={classes.actionTile}
        onClick={() => {
          trackEvent('Home Action Tile Clicked', { type: tile.text });
          if (!tile.useFeatureTooltip) {
            tile.ctaAction();
          }
        }}
      >
        {tile.icon}
        <Typography style={{ color: 'inherit', textAlign: 'center' }} variant="subtitle2">
          {tile.text}
        </Typography>
      </Grid>
    );
    if (tile.useFeatureTooltip) {
      return wrapWithFeatureTooltip(tile, content);
    }
    return content;
  };

  const businessStatsSection = (
    <Grid container direction="column" wrap="nowrap" style={{ gap: 8 }}>
      <Typography variant="h3">Business Stats</Typography>
      {transactions?.length === 0 && (
        <Banner className={classes.noSalesBanner} noIcon variant="success">
          <Typography style={{ color: 'inherit' }} variant="body2">
            Zero sales, unlimited potential! Let's create your success story! 🛍️✨
          </Typography>
        </Banner>
      )}
      <Grid container>
        <Stats />
      </Grid>
    </Grid>
  );

  const toDoSection = (
    <Grid container direction="column" wrap="nowrap">
      <Typography style={{ marginBottom: 8 }} variant="h3">
        To Do
      </Typography>
      <Grid container item wrap="nowrap" style={{ gap: 8 }}>
        {toDoTiles.map(tile => (
          <Grid
            container
            item
            key={tile.text}
            direction="column"
            alignItems="center"
            justify="space-between"
            className={classes.toDoTile}
            wrap="nowrap"
          >
            <Typography variant="subtitle2">{tile.text}</Typography>
            <Typography variant="h2">{tile.count}</Typography>
            <Typography
              variant="subtitle2"
              style={{ color: theme.branding.v2.blue[500], cursor: 'pointer' }}
              onClick={tile.ctaAction}
            >
              View All
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  return (
    <>
      {modal.modalType !== 'CHANGE_PLANS_MODAL' && (
        <Spinner show={isShopLoading || isProductsLoading || showSpinner} size={'fullscreen'} />
      )}
      <div>
        <Helmet>
          <title>Home | Castiron</title>
        </Helmet>
        <Grid className={classes.container} container>
          {discount && <RedeemCouponBanner />}
          {isVariant && (
            <Grid xs={12}>
              <SubscriptionBanner />
            </Grid>
          )}
          <Grid
            container
            direction="column"
            alignItems="center"
            wrap="nowrap"
            style={{ gap: isMobile ? 32 : 40, maxWidth: 800, margin: '0 auto' }}
          >
            <Grid alignItems="center" container direction="column" item style={{ gap: 8 }}>
              <Typography variant={isMobile ? 'h3' : 'h2'}>👋 Welcome, {shop.businessName}!</Typography>
              {shopStatusPill[shopStatusChecker]}
              {shopStatusChecker === 'active' && (
                <Button className={classes.pill} onClick={openShareLinksModal} variant="contained">
                  <Typography style={{ color: 'inherit' }} variant="caption">
                    Share My Shop
                    <LaunchIcon className={classes.launchIcon} />
                  </Typography>
                </Button>
              )}
            </Grid>

            {stripeStatus === 'READY' ? toDoSection : businessStatsSection}

            {/* action tiles */}
            <Grid container direction="column" wrap="nowrap" style={{ gap: 8 }}>
              <Typography variant="h3">Grow Your Biz</Typography>
              <Banner variant="info-blue" noIcon>
                <Typography variant="body4" style={{ color: theme.branding.v2.blue[500] }}>
                  Add more products and order forms to increase interactions with your shop on the Castiron Marketplace.
                </Typography>
              </Banner>
              <Grid
                container
                item
                wrap="nowrap"
                direction={isMobile ? 'column' : 'row'}
                className={classes.actionTilesContainer}
              >
                {actionTiles.map(tile => getActionTile(tile))}
              </Grid>
            </Grid>

            {stripeStatus === 'READY' ? businessStatsSection : toDoSection}

            {/* info tiles */}
            <Grid container item wrap="nowrap" className={classes.infoTilesContainer}>
              {showFirstMonthPromoBlock(account) ? <PromoBlock location="home" /> : <ReferralBlock location="home" />}
              {infoTiles.map(tile => (
                <Grid
                  container
                  item
                  key={tile.title}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  className={classes.infoTile}
                  wrap="nowrap"
                >
                  <img src={tile.image} style={{ width: 200 }} alt={tile.title} />
                  <Typography variant="h3">{tile.title}</Typography>
                  <Typography style={{ margin: '8px 0px 24px 0px' }} variant="body1">
                    {tile.text}
                  </Typography>
                  <Button
                    className={classes.infoTileButton}
                    onClick={() => {
                      trackEvent('Home Info Tile Clicked', { type: tile.title });
                      tile.ctaAction();
                    }}
                    variant="contained"
                  >
                    Visit
                  </Button>
                </Grid>
              ))}
            </Grid>

            {/* {!isTransactionsLoading && transactions && (
              <Tables ordersData={getOpenOrders(transactions)} productsData={products} />
            )} */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
