import React, { ReactElement, ReactNode } from 'react';
import { Tooltip as MUITooltip } from '@material-ui/core';
import Typography from '../Typography';

type Props = {
  title: ReactNode;
  children: ReactElement;
};

const Tooltip: React.FC<Props> = (props: Props) => {
  const { title, children } = props;

  return (
    <MUITooltip
      title={typeof title === 'string' ? <Typography variant="body2" style={{ color: 'inherit' }}>{title}</Typography> : title}
      arrow
      enterTouchDelay={0}
    >
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
