import { RequireExactlyOne } from 'type-fest';
import { Shop } from '../shop';
import { Customer } from '../customer';
import { Account } from '../account';
import { BaseDocument, BaseRepository, BaseStatus, FieldFunctions } from '../base/repository';
import firebase from 'firebase/compat';
import _firestore from '@google-cloud/firestore';
import moment from 'moment';

export type MessageType =
  | 'test'
  | 'newsletter'
  | 'newsletter_reminder'
  | 'thank_you_coupon'
  | 'artisan_quote_notification'
  | 'artisan_quote_ready'
  | 'artisan_upload_subscriber_list'
  | 'business_plan_interest'
  | 'customer_quote_notification'
  | 'customer_quote_ready'
  | 'domain_verification'
  | 'quote_receipt'
  | 'quote_rejected'
  | 'quote_updated'
  | 'customer_to_artisan_message'
  | 'artisan_to_customer_message'
  | 'fulfillment_notification'
  | 'launch_announcement'
  | 'payment_error'
  | 'payment_reminder'
  | 'post_payment_error'
  | 'prelaunch_subscriber'
  | 'newsletter_preview'
  | 'artisan_standard_order_receipt'
  | 'customer_standard_order_receipt'
  | 'subscriber_coupon'
  | 'subscription_cancel'
  | 'subscription_change'
  | 'subscription_payment_issue'
  | 'subscription_receipt'
  | 'subscription_resubscribe'
  | 'subscription_trial_expiration'
  | 'subscription_trial_started'
  | 'subscription_legacy_user_trial_start'
  | 'subscription_upgrade_during_trial'
  | 'subscription_upgrade_credit'
  | 'presale_announcement'
  | 'product_announcement'
  | 'pickup_reminder'
  | 'subscription_seven_day_expiration'
  | 'subscription_downgrade_proration'
  | 'claimed_profile_info'
  | 'sms_announcement'
  | 'email_announcement'
  | 'welcome_series'
  | 'newsletter_series';

export interface EmailEventSearchResult {
  type: MessageType;
  customerId: string;
  shopId: string;
  event: string;
  timestamp: number;
  messageId: string;
}

export interface MessageTo {
  shopId?: string;
  customerIds?: string[];
  castiron?: true;
  castironInternal?: true;
}

export interface MessageFrom {
  castiron?: true;
  shopId?: string;
  customerId?: string;
  castironInternal?: true;
}

export interface Message<T> {
  id: string;
  type: MessageType;
  to: RequireExactlyOne<MessageTo, 'shopId' | 'customerIds' | 'castiron' | 'castironInternal'>;
  from: RequireExactlyOne<MessageFrom, 'castiron' | 'castironInternal' | 'shopId' | 'customerId'>;
  subject?: string;
  content: T;
  scheduledMessageId?: string;
}

export interface SendResult {
  channel: 'email' | 'sms';
  customer: Customer;
  subject?: string;
  body?: string;
}

export interface SenderContext {
  shop?: Shop;
  account?: Account;
  customers?: Customer[];
  toCastiron: boolean;
  from: 'castiron' | 'artisan' | 'customer' | 'castironInternal';
  isTest?: boolean;
}
export type Sender<T> = (message: Message<T>, context: SenderContext) => Promise<SendResult[]>;

export interface ScheduledMessage<T> extends BaseDocument<ScheduledMessage<T>> {
  message: Message<T>;
  sendAt: number;
  status: BaseStatus | 'sent' | 'canceled';
}

export class ScheduledMessageRepository extends BaseRepository<ScheduledMessage<any>> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'scheduled_messages', fieldFunctions);
  }

  public async findMessagesToSend(startFrom: number): Promise<ScheduledMessage<any>[]> {
    return this.find({
      where: [
        { field: 'status', operator: '==', value: 'active' },
        { field: 'sendAt', operator: '<=', value: moment().unix() },
      ],
    });
  }

  public async findScheduledMessagesForShop(shopId: string, messageTypes: MessageType[], limit: number, orderBy: 'message.content.text' | 'message.content.subjectLine' | 'updatedAt' | 'createdAt' | 'sendAt', startAfter?: ScheduledMessage<any>): Promise<ScheduledMessage<any>[]> {
    return this.find({
      where: [
        {field: 'message.from.shopId', operator: '==', value: shopId},
        {field: 'message.type', operator: 'in', value: messageTypes},
        { field: 'status', operator: '==', value: 'active' },
        { field: 'sendAt', operator: '>', value: moment().unix() }
      ],
      orderBy: orderBy === 'sendAt' ? [{ field: 'sendAt', direction: 'desc'}] : [{ field: 'sendAt', direction: 'desc'}, { field: orderBy, direction: orderBy === 'message.content.subjectLine' || orderBy === 'message.content.text' ? 'asc' : 'desc'}],
      limit,
      startAfter,
    });
  }

  public async findScheduledMessagesForShopCount(shopId: string, messageTypes: MessageType[]): Promise<number> {
    return (await this.collection().where('message.from.shopId', '==', shopId ).where('message.type', 'in', messageTypes).where('status', '==', 'active').where('sendAt', '>', moment().unix()).get()).size;
  }

  public async markMessageAsSent(message: ScheduledMessage<any>) {
    await this.updateProps(message.id, { status: 'sent' });
  }
}
