import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { Theme, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ImageUploader from '../ImageUploader';
import Typography, { TypographyVariant } from '../Typography';
import Chip from '../Chip';
import { InputField, SelectedInputFields, SelectedAsset, Shop, CustomProduct } from '@castiron/domain';
import { upload } from '@castiron/castiron-firebase';
import { nanoid } from 'nanoid';
import InspoAssetDisplay from './InspoAssetDisplay';
import { useFormikContext } from 'formik';

type Props = {
  product: CustomProduct;
  variation: InputField;
  index: number;
  shop: Shop;
  onPhotoVarChange?: (variation: InputField, selectedValues: SelectedAsset[]) => void;
  uploadingStatus?: (uploading: boolean, selectedValues?: SelectedAsset[], numImages?: number) => void;
  readonly?: boolean;
  headerVariant?: TypographyVariant;
  showErrors?: boolean;
};

const MAX_ASSET_COUNT = 15;

const useStyles = makeStyles((theme: Theme) => ({
  ast: {
    color: theme.branding.red.primary,
  },
  chip: {
    maxHeight: 20,
    maxWidth: 48,
    marginLeft: 24,
    padding: '0px 8px',
  },
  error: {
    color: theme.branding.red.primary,
  },
  input: {
    minWidth: 150,
    width: '100%',
    padding: '0 16px 0 0',
  },
  inputInner: {
    width: '100%',
    fontSize: 14,
  },
  photoSubtitle: {
    fontSize: '14px',
  },
  pill: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 8,
    padding: '4px 8px',
    width: 'fit-content',
  },
  required: {
    background: theme.branding.v2.gray[100],
    border: `1px solid ${theme.branding.v2.gray[100]}`,
  },
  requiredError: {
    background: theme.branding.v2.red[50],
    border: 'none',
    color: theme.branding.v2.red[500],
  },
  select: {
    width: '100%',
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'break-spaces',
    },
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  uploaderContainer: {},
  variationLabel: {
    color: theme.branding.gray[800],
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    margin: '1.5em 0 1em',
  },
}));

const PhotoVariation: React.FC<Props> = (props: Props) => {
  const {
    index,
    variation,
    shop,
    product,
    onPhotoVarChange,
    uploadingStatus,
    readonly = false,
    headerVariant,
    showErrors = false,
  } = props;
  const classes = useStyles();

  const { setFieldValue }: any = useFormikContext();

  const [imageArr, setImageArr] = useState<SelectedAsset[]>([]);
  /* we support moving forward without verifying uploads, so count could be different than actual array size */
  const [imageCount, setImageCount] = useState(0);
  const [imagesRemainingToUpload, setImagesRemainingToUpload] = useState(0);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [uploading, setUploading] = useState({ isUploading: false, id: '' });
  const [uploadTimeout, setUploadTimeout] = useState({ isFailed: false, id: '' });
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [notes, setNotes] = useState('');
  const [imageFile, setImageFile] = useState({ imgFile: undefined, id: '' });
  const [uploadData, setUploadData] = useState({
    callbacks: { success: undefined, error: undefined },
    options: { folder: '' },
    context: {},
    id: '',
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleNotesChange = (updatedImage: SelectedAsset, variation: InputField) => {
    setImageArr(imageArr => {
      /* need to keep the image in the same place in the array */
      const updatedImgIndex = _.findIndex(imageArr, img => img.id === updatedImage.id);
      const newImgArr = _.concat(imageArr.slice(0, updatedImgIndex), updatedImage, imageArr.slice(updatedImgIndex + 1));

      const fieldVal = {
        inputFieldId: variation.id,
        inputFieldName: variation.name,
        selectedValues: [],
        selectedAssets: newImgArr,
      };

      setFieldValue(`selectedFields[${index}]`, fieldVal);
      onPhotoVarChange && onPhotoVarChange(variation, fieldVal.selectedAssets);

      return newImgArr;
    });
  };

  const handleUploadSuccess = (downloadUrl, metadata, options, context) => {
    setUploading({ isUploading: false, id: metadata.id });
    setUploadTimeout({ isFailed: false, id: metadata.id });
    setImagesRemainingToUpload(imagesRemainingToUpload => imagesRemainingToUpload - 1);

    setImageArr(imgArr => {
      const currentImg = imgArr.find(image => image.id === metadata.id);
      const currentNotes = currentImg.notes;

      const imageObject = {
        id: metadata.id,
        title: metadata.originalFilename,
        asset: {
          id: metadata.id,
          downloadUrl: downloadUrl,
          shopId: shop.id,
          smallVersion: {
            downloadUrl: downloadUrl,
            width: 120,
            height: 120,
          },
          mediumVersion: {
            downloadUrl: downloadUrl,
            width: 250,
            height: 250,
          },
          metadata,
        },
        notes: currentNotes,
      };

      const updatedImgIndex = _.findIndex(imgArr, img => img.id === imageObject.id);
      const newImgArr = _.concat(imgArr.slice(0, updatedImgIndex), imageObject, imgArr.slice(updatedImgIndex + 1));

      const fieldVal = {
        inputFieldId: variation.id,
        inputFieldName: variation.name,
        selectedValues: [],
        selectedAssets: newImgArr,
      };

      setFieldValue(`selectedFields[${index}]`, fieldVal);
      onPhotoVarChange && onPhotoVarChange(variation, fieldVal.selectedAssets);

      return newImgArr;
    });
  };

  const handleUploadFailure = (error, callbacks, metadata) => {
    setUploadTimeout({ isFailed: true, id: metadata?.id });
    setImagesRemainingToUpload(imagesRemainingToUpload => imagesRemainingToUpload - 1);
  };

  useEffect(() => {
    if (uploadingStatus) {
      imagesRemainingToUpload
        ? uploadingStatus(true, imageArr, imagesRemainingToUpload)
        : uploadingStatus(false, imageArr, imagesRemainingToUpload);
    }
  }, [imagesRemainingToUpload]);

  const handleFile = file => {
    const id = nanoid();
    const downloadUrl = URL.createObjectURL(file);
    setImageFile({ imgFile: file, id });

    const metadata = {
      id,
      contentType: file.type,
      assetType: 'inspoImage',
      shopId: shop.id,
      originalFilename: file.name,
    };
    const callbacks = {
      success: handleUploadSuccess,
      error: handleUploadFailure,
    };
    const options = {
      folder: `order/${shop.id}`,
    };
    const context = {
      product,
    };

    setUploadData({
      callbacks,
      options,
      context,
      id,
    });

    upload(file, metadata, options, callbacks, context);
    setUploading({ isUploading: true, id });

    const imageObj = {
      id: id,
      title: file.name,
      asset: {
        id: id,
        downloadUrl: downloadUrl,
        shopId: shop.id,
        smallVersion: {
          downloadUrl: downloadUrl,
          width: 120,
          height: 120,
        },
        mediumVersion: {
          downloadUrl: downloadUrl,
          width: 250,
          height: 250,
        },
        metadata,
        callbacks,
        options,
      },
      notes,
    };

    setImageArr(imageArr => [...imageArr, imageObj]);
  };

  useEffect(() => {
    onPhotoVarChange && onPhotoVarChange(variation, imageArr);
  }, [imageArr]);

  const handleImageArrChange = useCallback(
    (arr: SelectedAsset[]) => {
      setImageArr(arr);
      setImageCount(arr.length);

      const fieldVal = {
        inputFieldId: variation.id,
        inputFieldName: variation.name,
        selectedValues: [],
        selectedAssets: arr,
      };
      setFieldValue(`selectedFields[${index}]`, fieldVal);
      onPhotoVarChange(variation, arr);
    },
    [setImageArr],
  );

  useEffect(() => {
    imageCount >= MAX_ASSET_COUNT && setMaxFilesError(true);
    imageCount < MAX_ASSET_COUNT && setMaxFilesError(false);
  }, [imageCount]);

  const handleFileDrop = files => {
    setFileSizeError(false);

    const totalImageCount = imageCount + files.length;
    setImageCount(Math.min(totalImageCount, MAX_ASSET_COUNT));

    const overMax = totalImageCount >= MAX_ASSET_COUNT;
    const numImagesToUpload = overMax ? MAX_ASSET_COUNT - imageCount : files.length;
    const imagesToUpload = files.slice(0, numImagesToUpload);

    setImagesRemainingToUpload(imagesRemainingToUpload => imagesRemainingToUpload + imagesToUpload.length);

    imagesToUpload.forEach(file => {
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 5) {
        setFileSizeError(true);
      } else if (imageArr.length <= 15) {
        handleFile(file);
      } else {
        return;
      }
    });
  };

  const optionalOrRequiredPill = (
    <Typography
      className={`${classes.pill} ${
        showErrors && variation?.required ? classes.requiredError : variation?.required ? classes.required : ''
      }`}
      variant="caption"
    >
      {variation?.required ? 'Required' : 'Optional'}
    </Typography>
  );

  return (
    <>
      <Grid container justify="space-between" wrap={isMobile ? "wrap" : "nowrap"} alignItems="center">
        <Grid container item wrap="nowrap" alignItems="center">
          <Typography className={`${!headerVariant && classes.variationLabel}`} variant={headerVariant || 'h4'}>
            {variation.name}{' '}
          </Typography>
          <Chip className={classes.chip} colorScheme="info" title={'Number of photos uploaded'}>
            {imageArr.length + '/15'}
          </Chip>
        </Grid>
        <Grid item>
          {optionalOrRequiredPill}
        </Grid>
      </Grid>
      {variation.description && (
        <Typography style={{ color: theme.branding.v2.gray[500], marginBottom: 8 }} variant="body2">
          {variation.description}
        </Typography>
      )}
      <Typography variant="body4" className={classes.photoSubtitle}>
        PNG or JPG files only. 5MB max file size.
      </Typography>
      {fileSizeError && (
        <Typography className={classes.error} variant="body4">
          Images must be under 5MB
        </Typography>
      )}
      <div className={classes.uploaderContainer}>
        <ImageUploader
          imageInProduct={true}
          maxFilesError={maxFilesError}
          closeToMax={imageArr.length === 14}
          maxFiles={15}
          dropZoneText={
            imageArr.length >= 15
              ? 'You have all 15 photos uploaded. Remove a photo to upload a new one.'
              : 'Drag and drop photos here'
          }
          onFileDrop={readonly ? () => {} : handleFileDrop}
        />
      </div>

      <Grid>
        {imageArr.length > 0 &&
          imageArr.map((image, index) => {
            return (
              <InspoAssetDisplay
                image={image}
                index={index}
                variation={variation}
                imageArr={imageArr}
                handleNotesChange={handleNotesChange}
                handleImageArrChange={handleImageArrChange}
                uploadTimeout={uploadTimeout}
                uploading={uploading}
                imageFile={imageFile}
                uploadData={uploadData}
              />
            );
          })}
      </Grid>
    </>
  );
};

export default PhotoVariation;
