import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardHeader, { CardHeaderProps } from './CardHeader';

interface Props extends CardHeaderProps {
  className?: string;
  children?: ReactNode;
  noMargin?: boolean;
  removeChildPadding?: boolean;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
    /* I really want to get rid of this,
     * external positioning should not be in the purview of this component IMO
     * -KB
     */
    marginBottom: 24,
  },
  innerContainer: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '24px',
  },
  innerContainerNoPadding: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
  },
  noMargin: {
    margin: 0,
  },
}));

const Card: React.FC<Props> = (props: Props) => {
  const { children, className, noMargin = false, removeChildPadding = false, style } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      className={clsx(classes.container, className, noMargin && classes.noMargin)}
      direction="column"
      component="section"
      style={style}
    >
      <Grid item>
        <CardHeader {...props} />
      </Grid>
      <Grid item className={removeChildPadding ? classes.innerContainerNoPadding : classes.innerContainer}>
        <Grid container item direction="column">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Card;
