const sources: Record<string, RegExp[]> = {
  facebook: [/(.*?)facebook.com(.*?)/],
  instagram: [/(.*?)instagram.com(.*?)/],
  google: [/(.*?)google.com(.*?)/],
  bing: [/(.*?)bing.com(.*?)/],
  twitter: [/(.*?)twitter.com(.*?)/, /(.*?)t.co(.*?)/],
  tiktok: [/(.*?)tiktok.com(.*?)/],
  linkedin: [/(.*?)linkedin.com(.*?)/],
  nextdoor: [/(.*?)nextdoor.com(.*?)/],
  castiron: [/(.*?)castiron.me(.*?)/, /(.*?)shopcastiron.com(.*?)/],
  dev: [/(.*?)localhost(.*?)/],
  qa: [/(.*?)firebaseapp.com(.*?)/],
  direct: null,
  unknown: null,
};

export type TrafficSource = keyof typeof sources;

export type EntryType = 'campaign' | 'seo' | 'organic' | 'direct' | 'social';

export interface UtmParams {
  source: string;
  medium: string;
  campaign: string;
  term: string;
  content: string;
}

export interface GATracking {
  ga_client_id?: string;
  ga_session_id?: string;
}

export interface Attribution {
  source?: TrafficSource;
  campaignId?: string;
  referringSourceUrl?: string;
  utmParams?: UtmParams;
  gaTracking?: GATracking;
  anonymousId?: string;
  userAgent?: string;
  ip?: string;
  entryUrl?: string;
  entryType?: EntryType;
  tags?: string[];
  trackingIds?: {
    gmcId?: string;
    gclId?: string;
  };
}

const realSources = Object.fromEntries(Object.entries(sources).filter(e => e[1] !== null));

export const getSource = (referrer: string): TrafficSource => {
  const sourceEntry = Object.entries(realSources).find(e => !!e[1].find(regex => regex.test(referrer)));
  return (sourceEntry ? sourceEntry[0] : 'unknown') as TrafficSource;
};
