import React, { useEffect, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { FiberManualRecord, FiberManualRecordOutlined } from '@material-ui/icons';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import clsx from 'clsx';
import {
  Banner,
  Button,
  CollapsableCard,
  InputLabel,
  MoneyInput,
  QualifiedNumberInput,
  Select,
  TextInput,
  Typography,
} from '@castiron/components';
import {
  addressSchema,
  ChecklistValues,
  FulfillmentOption,
  FulfillmentType,
  fulfillmentTypeDisplayName,
  FulfillmentOptionSchedule,
  TimePeriod,
  shopToEventModel,
} from '@castiron/domain';
import { defaultTimeZone, isset, removeEmpty, useTracking } from '@castiron/utils';
import { shopRepository } from '../../../../domain';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getShopAction, updateChecklistAction } from '../../../../store/reducers/shops';
import AdminForm from '../../../AdminForm';
import { LayoutPageProps } from '../../../Layout';
import findHasPaidSub from '../../../../lib/findHasPaidSub';
import { getSubscriptionStatus } from '../../../../lib/accountUtils';
import DeleteButton from '../DeleteButton';
import DeleteDialog from '../DeleteDialog';
import PickupDetails from './PickupDetails';
import DeliveryDetails from './DeliveryDetails';
import ShippingDetails from './ShippingDetails';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';

interface Params {
  id?: string;
  type?: FulfillmentType;
}

export const EmptyTimePeriod: TimePeriod = {
  id: nanoid(),
  startTime: null,
  endTime: null,
};

export const EmptyFulfillmentSchedule: FulfillmentOptionSchedule = {
  id: nanoid(),
  type: 'fixed',
  dates: [],
  description: '',
};

const fulfillmentSchema = (pageType: FulfillmentType) =>
  yup.object().shape({
    status: yup
      .string()
      .oneOf(['active', 'inactive', 'archived'])
      .required(),
    type: yup.string().required(),
    displayName: yup.string().required('Please enter a title.'),
    leadTime: yup.object({
      increments: yup
        .number()
        .typeError('Please enter a number.')
        .test('Is positive?', 'Please enter a positive number', value => value >= 0),
      incrementType: yup.string().oneOf(['hour', 'day']),
    }),
    description: yup.string().max(2500, 'Must be less than 2500 characters.'),
    afterPurchaseDetails: yup.string().max(2500, 'Must be less than 2500 characters.'),
    fee: yup.number().nullable(),
    minimum: yup.number().nullable(),
    postalCode: yup.string().nullable(), // legacy field saved as "Zip Code, Neighborhood, Borough".
    address: addressSchema(pageType === 'pickup'),
    processingTime: yup.object({
      increments: yup.number().typeError('Please enter a number.'),
      incrementType: yup.string().oneOf(['day', 'week']),
    }),
    schedule: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          type: yup
            .string()
            .oneOf(['fixed', 'flexible'])
            .required(),
          dates: yup
            .array()
            .of(
              yup.object({
                id: yup.string().nullable(),
                startTime: yup.number().nullable(),
                endTime: yup.number().nullable(),
                repeatWeekly: yup.boolean().nullable(),
              }),
            )
            .nullable(),
          description: yup.string().when('type', {
            is: val => val === 'flexible',
            then: schema => schema.required('Please enter flexible fulfillment details.'),
          }),
        }),
      )
      .nullable(),
    sendPickupReminderEmail: yup.boolean(),
    useBusinessAddress: yup.boolean(),
  });

const errorToFieldName = (error: string, fulfillmentType: FulfillmentType) => {
  switch (error) {
    case 'displayName':
      return 'Title';
    case 'description':
      return 'Details';
    case 'postalCode':
      return 'Neighborhood/Borough/Zip Code';
    case 'address':
      return `${fulfillmentTypeDisplayName(fulfillmentType)} Address`;
  }
  return null;
};

const parentPage = '/store/fulfillment';
const useStyles = makeStyles((theme: Theme) => ({
  activeIcon: {
    color: theme.branding.green.primary,
    marginRight: 5,
  },
  archivedIcon: {
    fontSize: 20,
    marginLeft: 3,
    marginRight: 5,
  },
  basicInformation: {
    marginTop: 24,
  },
  container: {
    /* hack until we get the main container aligned to the header margins */
    padding: '0 41px 0 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  errorFields: {
    fontWeight: 700,
    color: 'inherit',
    textDecoration: 'underline',
    display: 'inline',
  },
  feeContainer: {
    paddingLeft: 24,
  },
  footerButton: {
    margin: '0px 4px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  inactiveIcon: {
    color: theme.branding.gray[600],
    marginRight: 5,
  },
  select: {
    margin: '4px 0',
    width: '100%',
  },
  productPageLink: {
    fontWeight: 700,
    textDecoration: 'underline',
  },
}));

const EditFulfillment: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const { id, type } = useParams<Params>();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const hasPaidSub = findHasPaidSub();

  const { account, shop, fulfillments } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    fulfillments: state.shops.fulfillments,
  }));

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [basicInfoExpanded, setBasicInfoExpanded] = useState<boolean>(true);
  const [feeExpanded, setFeeExpanded] = useState<boolean>(true);

  const formRef = useRef<FormikProps<any>>();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isEditing = isset(id);
  const existingFulfillment = isEditing ? fulfillments.find(ff => ff.id === id) : null;
  const pageType = existingFulfillment?.type || type;

  const toggleBasicInfoExpand = () => {
    setBasicInfoExpanded(!basicInfoExpanded);
  };

  const toggleFeeExpand = () => {
    setFeeExpanded(!feeExpanded);
  };

  /* have to maintain this locally, since changes to form values won't trigger rerender */
  const [ffStatus, setFFStatus] = useState(existingFulfillment?.status || 'active');

  useEffect(() => {
    if (isEditing && !existingFulfillment) {
      console.error('No fulfillment found for id: ', id);
      history.push(parentPage);
    }
    setBackLocation(true);
    setHeaderCTAs([]);
    setFooterCTAs([
      isEditing && <DeleteButton variant="footer" onDelete={() => setDeleteDialogOpen(true)} />,
      <Button variant="outlined" onClick={() => history.push(parentPage)} className={classes.footerButton}>
        Discard
      </Button>,
      <Button
        variant="contained"
        onClick={async () => {
          if (!formRef.current.isSubmitting) {
            setHasSubmitted(true);

            await formRef.current.validateForm();
            if (!formRef.current.isValid) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }

            formRef.current.submitForm();
          }
        }}
        className={classes.footerButton}
      >
        Save
      </Button>,
    ]);

    return () => {
      setBackLocation(false);
      setFooterCTAs([]);
    };
  }, []);

  useEffect(() => {
    const basePageTitle = `${fulfillmentTypeDisplayName(pageType, 'short')} Option`;
    if (isMobile) {
      setPageTitle(basePageTitle);
    } else {
      setPageTitle(`${isEditing ? 'Edit' : 'Add'} ${basePageTitle}`);
    }
  }, [ffStatus, isMobile]);

  const useBusinessAddress =
    existingFulfillment && 'useBusinessAddress' in existingFulfillment
      ? existingFulfillment?.useBusinessAddress
      : type === 'pickup';

  const initialValues: FulfillmentOption = {
    status: existingFulfillment?.status || 'active',
    type: existingFulfillment?.type || type,
    displayName: existingFulfillment?.displayName || '',
    leadTime: existingFulfillment?.leadTime || {
      increments: 0,
      incrementType: 'hour',
    },
    description: existingFulfillment?.description || '',
    afterPurchaseDetails: existingFulfillment?.afterPurchaseDetails || '',
    fee: existingFulfillment?.fee || 0,
    minimum: existingFulfillment?.minimum || 0,
    postalCode: existingFulfillment?.postalCode || '',
    address: existingFulfillment?.address ||
      (useBusinessAddress && shop?.physicalAddress) || {
        fullAddress: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        regionName: '',
        country: '',
        postalCode: '',
      },
    processingTime: existingFulfillment?.processingTime || {
      increments: -1,
      incrementType: 'day',
    },
    schedule: existingFulfillment?.schedule || EmptyFulfillmentSchedule,
    sendPickupReminderEmail: false,
    useBusinessAddress,
  };

  const onStatusChange = async e => {
    formRef.current.setFieldValue('status', e.target.value);
    setFFStatus(e.target.value);
  };

  const onSubmit = async (values: FulfillmentOption) => {
    const hasFul = (await shop.getFulfillmentOptions()).length > 0;

    const saveProcessingTime = values.processingTime.increments && values.processingTime.increments >= 0;

    let tempSave = {
      ...existingFulfillment,
      ...values,
      processingTime: saveProcessingTime ? values.processingTime : null,
    } as FulfillmentOption;

    // clean up schedule date/times if shipping or empty'
    if (tempSave.type == 'shipping') {
      delete tempSave.schedule;
    }
    if (tempSave.schedule && tempSave.schedule.dates.length > 0) {
      if (tempSave.schedule.type === 'fixed' && !tempSave.schedule.dates[0].startTime) {
        delete tempSave.schedule;
      } else {
        tempSave = {
          ...tempSave,
          schedule: {
            ...tempSave.schedule,
            dates: tempSave.schedule.dates.filter(date => date.startTime),
          },
        };
        /* we are saving dates, let's check and see if a timezone is set in the config */
        if (!shop.config?.timeZone) {
          /* if not, set one */
          await shopRepository.updateProps(shop.id, {
            config: {
              ...shop.config,
              timeZone: defaultTimeZone,
            },
          });
        }
      }
    }

    /* HACK: remove empty call is blowing up here due to read only props for reasons I don't understand at the moment, make a copy */
    const copyOfTemp = JSON.parse(JSON.stringify(tempSave));
    const toSave = removeEmpty(copyOfTemp) as FulfillmentOption;

    // remove empty dates from schedule.dates
    if (toSave.schedule) {
      toSave.schedule = removeEmpty(toSave.schedule) as FulfillmentOptionSchedule;
    }

    if (isEditing) {
      await shop.updateFulfillmentOption(id, toSave);
      if (existingFulfillment.processingTime && !saveProcessingTime) {
        await shop.deleteFulfillmentOptionProperties(id, ['processingTime']);
      }
      await dispatch(getShopAction(shop.id));
      history.push(`/store/fulfillment`);
    } else {
      await shop.addFulfillmentOption(toSave);
      if (!shop.checklistCompletions?.includes(ChecklistValues.FulfillmentAdded)) {
        await dispatch(updateChecklistAction({ shop, items: [ChecklistValues.FulfillmentAdded] }));
      }
      await dispatch(getShopAction(shop.id));
      history.push(`/store/fulfillment`);
    }

    trackEvent('Shop Fulfillment Action', {
      action: isEditing ? 'updated' : 'created',
      fulfillment: toSave,
      subscriptionStatus: getSubscriptionStatus(account),
    });

    if (!hasFul) {
      trackEvent('Owner Added First Fulfillment Option', {
        fulfillment: toSave,
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={fulfillmentSchema(pageType)}
        onSubmit={onSubmit}
        innerRef={formRef}
        validateOnBlur={hasSubmitted}
        validateOnChange={hasSubmitted}
      >
        {(formikProps: FormikProps<FulfillmentOption>) => (
          <AdminForm>
            <DeleteDialog
              open={deleteDialogOpen}
              option={existingFulfillment}
              onClear={() => setDeleteDialogOpen(false)}
            />
            <Grid container>
              <Grid item xs={12} md={8}>
                <Grid container direction="column">
                  {hasSubmitted && _.keys(formikProps.errors).length > 0 && (
                    <Grid item>
                      <Banner variant="error">
                        <Typography variant="body2" style={{ color: 'inherit', display: 'inline' }}>
                          Please fill out all of the required information:&nbsp;
                        </Typography>
                        <Typography variant="body2" className={classes.errorFields}>
                          {_.keys(formikProps.errors)
                            .map(err => errorToFieldName(err, pageType))
                            .join(', ')}
                        </Typography>
                      </Banner>
                    </Grid>
                  )}
                  <CollapsableCard
                    title="Basic Information"
                    handleExpand={toggleBasicInfoExpand}
                    expanded={basicInfoExpanded}
                    noScroll
                    className={classes.basicInformation}
                  >
                    <TextInput
                      name="displayName"
                      placeholder="Title"
                      required={true}
                      label="Title"
                      error={formikProps.touched.displayName && formikProps.errors.displayName}
                    />
                    <InputLabel primaryLabel="Status" required={true} />
                    <Select
                      options={[
                        {
                          label: (
                            <Typography variant="button">
                              <FiberManualRecord className={classes.activeIcon} />
                              Active
                            </Typography>
                          ),
                          value: 'active',
                        },
                        {
                          label: (
                            <Typography variant="button">
                              <FiberManualRecordOutlined className={classes.inactiveIcon} />
                              Inactive
                            </Typography>
                          ),
                          value: 'inactive',
                        },
                        {
                          label: (
                            <Typography variant="button">
                              <ArchiveOutlinedIcon className={classes.archivedIcon} />
                              Archive
                            </Typography>
                          ),
                          value: 'archived',
                        },
                      ]}
                      value={formikProps.values.status}
                      onChange={onStatusChange}
                      selectClass={classes.select}
                    />
                    {hasPaidSub &&
                      formikProps.values.schedule?.type == 'fixed' &&
                      formikProps.values.type !== 'shipping' && (
                        <Grid container style={{ marginTop: 10 }}>
                          <QualifiedNumberInput
                            label="Lead Time"
                            secondaryLabel="How much time do you need for an order to be ready?"
                            required={true}
                            fieldMappings={{
                              text: 'leadTime.increments',
                              qualifier: 'leadTime.incrementType',
                            }}
                            qualifierOptions={[
                              { display: 'Hours', value: 'hour' },
                              { display: 'Days', value: 'day' },
                            ]}
                            error={formikProps.touched.leadTime?.increments && formikProps.errors.leadTime?.increments}
                          />
                        </Grid>
                      )}
                  </CollapsableCard>
                  {formikProps.values.type === 'pickup' && (
                    <Grid item>
                      <PickupDetails />
                    </Grid>
                  )}
                  {formikProps.values.type === 'delivery' && (
                    <Grid item>
                      <DeliveryDetails />
                    </Grid>
                  )}
                  {formikProps.values.type === 'shipping' && (
                    <Grid item>
                      <ShippingDetails />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} className={isMobile ? '' : classes.feeContainer}>
                <Grid container direction="column">
                  <Grid item>
                    <CollapsableCard
                      title="Fees & Minimums"
                      handleExpand={toggleFeeExpand}
                      expanded={feeExpanded}
                      noScroll
                      className={classes.basicInformation}
                    >
                      <Grid container direction="column" spacing={3}>
                        <Grid item>
                          <MoneyInput
                            label={`${fulfillmentTypeDisplayName(formikProps.values.type, 'short')} Fee (Optional)`}
                            name="fee"
                          />
                        </Grid>
                        <Grid item>
                          <MoneyInput label={`Order Minimum (Optional)`} name="minimum" />
                        </Grid>
                      </Grid>
                    </CollapsableCard>
                  </Grid>
                  <Grid item>
                    <Banner variant="info-blue" iconAtStart>
                      <Typography variant="body2" style={{ color: 'inherit' }}>
                        Please note: This option only applies to instant checkout products. Order Form fulfillment can
                        be updated by visiting your{' '}
                        <Link to="/order-forms" className={classes.productPageLink}>
                          Order Forms Page
                        </Link>{' '}
                        and clicking into a specific Order Form.
                      </Typography>
                    </Banner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Box>
  );
};

export default EditFulfillment;
