import React from 'react';
import { ButtonV2 as Button } from '../Button';
import Price from '../Price';
import Typography from '../Typography';
import { useTheme } from '@material-ui/core';

type Props = {
  price: number;
  onClick?: () => void;
  disabled?: boolean;
  form?: string;
  type?: 'button' | 'reset' | 'submit';
  textColor?: string;
  isSoldOut?: boolean;
  isPastEvent?: boolean;
};

const AddToCartButton: React.FC<Props> = (props: Props) => {
  const { price, onClick, disabled, type, form, textColor, isSoldOut = false, isPastEvent = false} = props;

  const theme = useTheme();

  const disableButton = disabled || isSoldOut || isPastEvent;

  return (
    <Button
      disabled={disableButton}
      onClick={onClick}
      type={type}
      form={form}
      style={disableButton ? { backgroundColor: theme.branding.v2.gray[200] } : {}}
      variant="contained"
      fullWidth
    >
      {isPastEvent ? (
        <Typography style={{ color: theme.branding.v2.gray[400] }} variant="button">
          Past Event
        </Typography>
      ) : isSoldOut ? (
        <Typography style={{ color: theme.branding.v2.gray[400] }} variant="button">
          Sold Out
        </Typography>
      ) : (
        <>
          <Typography
            style={{ color: disableButton ? theme.branding.v2.gray[400] : textColor ? textColor : 'initial' }}
            variant="button"
          >
            Add to Cart •&nbsp;
          </Typography>
          <Price amount={price} textColor={disableButton ? theme.branding.v2.gray[400] : textColor} />
        </>
      )}
    </Button>
  );
};

export default AddToCartButton;
