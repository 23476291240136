import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { Button, CollapsableCard, Link, TextAreaInput } from '@castiron/components';
import { FulfillmentOption, FulfillmentOptionSchedule, fulfillmentTypeDisplayName } from '@castiron/domain';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import { EmptyTimePeriod } from '../index';
import AddDateTimeOptionsModal from './AddDateTimeOptionsModal';

type Props = {
  fulfillmentType?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  addScheduleButton: {
    padding: 16,
  },
  displayScheduleContainer: {
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    marginBottom: 16,
    padding: 24,
  },
  recommendationText: {
    marginBottom: 24,
    fontWeight: 500,
  },
  repeatContainer: {
    color: theme.branding.v2.gray[500],
  },
  selectInput: {
    width: '100%',
  },
  toggleButtonGroup: {
    maxHeight: 65,
    [theme.breakpoints.down(380)]: {
      maxHeight: '100%',
    },
  },
  toggleButton: {
    border: 'none',
    color: theme.branding.gray[800],
    fontSize: 16,
    width: '50%',

    '&.Mui-selected': {
      backgroundColor: theme.branding.blue.light,
      color: theme.branding.blue.primary,
    },

    '&.MuiToggleButton-root': {
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
    },
  },
  toggleGroup: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    padding: 4,
    width: '100%',
  },
}));

const DateTimeOptions: React.FC<Props> = (props: Props) => {
  const { fulfillmentType } = props;
  const formik = useFormikContext<FulfillmentOption>();
  const classes = useStyles();

  const [viewType, setViewType] = useState('fixed');
  const [dateTimeError, setDateTimeError] = useState(['']);
  const pageType = formik.values.type;
  const [expanded, setExpanded] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [scheduleIndex, setScheduleIndex] = useState<number>(0);
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (formik.values.schedule.type) {
      setViewType(formik.values.schedule.type);
    }
  }, []);

  useEffect(() => {
    if (formik.values.schedule.type === 'fixed' && dateTimeError.length > 0) {
      setTimeout(() => {
        setDateTimeError(['']);
      }, 5000);
    }
  }, [dateTimeError]);

  const onButtonToggle = (event, selectedView: FulfillmentOptionSchedule['type']): void => {
    setViewType(selectedView);
    formik.setFieldValue('schedule.type', selectedView);
    formik.setFieldValue('sendPickupReminderEmail', selectedView === 'fixed' ? true : false);
  };

  const openAddDateTimeOptionsModal = index => {
    if (index) {
      setScheduleIndex(index);
      setIsAdd(false);
      setOpenModal(true);
    } else {
      setScheduleIndex(formik.values?.schedule?.dates?.length + 1);
      setIsAdd(true);
      setOpenModal(true);
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <CollapsableCard
          title={`${fulfillmentTypeDisplayName(pageType, 'short')} Schedule`}
          handleExpand={toggleExpand}
          expanded={expanded}
          noScroll
        >
          <Grid container direction="column">
            <Grid item className={classes.toggleButtonGroup}>
              <ToggleButtonGroup
                color="primary"
                exclusive
                onChange={onButtonToggle}
                size="large"
                value={viewType}
                className={classes.toggleGroup}
                aria-label={`${fulfillmentTypeDisplayName(pageType, 'short')} Options`}
              >
                <ToggleButton
                  color="primary"
                  value="fixed"
                  aria-label="Specific Date(s)"
                  role="button"
                  className={classes.toggleButton}
                >
                  Specific Date(s)
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="flexible"
                  aria-label="I'm Flexible"
                  role="button"
                  className={classes.toggleButton}
                >
                  I'm Flexible
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item className={classes.recommendationText}>
              <Typography variant="caption">
                {viewType === 'fixed' ? (
                  <>
                    <span style={{ fontWeight: 'bold' }}>Recommended Option.</span> Specific dates integrate into your
                    calendar, enable reporting, and more.
                  </>
                ) : (
                  <>
                    <span style={{ fontWeight: 'bold' }}>Not Recommended.</span> These orders will not appear on your
                    calendar or in reporting.
                  </>
                )}
              </Typography>
            </Grid>
            {formik.values.schedule.type === 'fixed' ? (
              <Grid container direction="column">
                <AddDateTimeOptionsModal open={openModal} setOpen={setOpenModal} count={scheduleIndex} isAdd={isAdd} />
                {formik.values.schedule?.dates?.length > 0 && (
                  <>
                    {formik.values.schedule?.dates?.map((date, index) => {
                      const startTime = moment.unix(date.startTime);
                      const endTime = moment.unix(date.endTime);
                      const count = ++index;
                      return (
                        <Grid container className={classes.displayScheduleContainer}>
                          <Grid container item justify="space-between" alignItems="center" xs={12}>
                            <Typography variant="subtitle1">{`${fulfillmentTypeDisplayName(
                              pageType,
                              'short',
                            )} ${count}`}</Typography>
                            <Button variant="text" onClick={() => openAddDateTimeOptionsModal(index)}>
                              Edit
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2">{`${startTime
                              .format('dddd')
                              .substring(0, 3)}, ${startTime.format('MM/DD/YYYY')}, ${startTime.format(
                              'hh:mm A',
                            )} - ${endTime.format('hh:mm A')}`}</Typography>
                          </Grid>
                          {date?.repeatWeekly && (
                            <Grid item xs={12} style={{ marginTop: 8 }}>
                              <Typography variant="body2" className={classes.repeatContainer}>
                                {' '}
                                <ReplayOutlinedIcon style={{ marginRight: 7 }} />
                                Repeats weekly
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                  </>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => openAddDateTimeOptionsModal(null)}
                  className={classes.addScheduleButton}
                >
                  + Add
                </Button>
                <Grid container>
                  <Typography variant="body2" style={{ marginTop: 24 }}>
                    All options are created and displayed to shoppers in your time zone. You can edit your time zone in{' '}
                    <Link href="/account-settings" target="_blank">
                      Account Settings
                    </Link>
                    .
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="column">
                <TextAreaInput
                  label="Availability"
                  name="schedule.description"
                  rowCount={4}
                  required={formik.values.schedule.type === 'flexible' ? true : false}
                  value={formik.values.schedule.description}
                  secondaryLabel={`Provide details on your availability to help coordinate a ${fulfillmentType} time. Please note: customers will be asked to submit preferred ${fulfillmentType} times based on your availability.`}
                />
              </Grid>
            )}
          </Grid>
        </CollapsableCard>
      </Grid>
    </Grid>
  );
};

export default DateTimeOptions;
