import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ButtonBase, List, Grid, Hidden } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import PeopleOutlineOutlined from '@material-ui/icons/PeopleOutlineOutlined';
import PieChartOutlined from '@material-ui/icons/PieChartOutlined';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import CropOriginalOutlinedIcon from '@material-ui/icons/CropOriginalOutlined';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import {
  AppearanceIcon,
  CalendarIcon,
  CookieOutlinedIcon,
  Logo,
  MegaphoneOutlinedIcon,
  RequestQuoteOutlined,
  Typography,
  GiftOutlinedIcon,
} from '@castiron/components';
import { backendStateToFrontendState } from '@castiron/domain';
import { FeatureFlag } from '@castiron/castiron-firebase';
import { useTracking } from '@castiron/utils';
import { useAppSelector } from '../../../../hooks';
import AvatarMenu from '../../../Menus/AvatarMenu';
import NavItem from './NavItem/NavItem';
import { DriveEtaOutlined, SettingsOutlined } from '@material-ui/icons';
import PromoBlock, { showFirstMonthPromoBlock } from '../../../ReferralPrompts/PromoBlock';

type Props = {
  role: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    padding: '16px 8px 0px 8px',
    color: theme.branding.v2.gray[600],
  },
  giftIcon: {
    color: theme.branding.v2.green[500],
    height: 18,
    width: 18,
  },
  logoContainer: {
    // padding to help it match the sticky header height
    padding: '30px 0 25px 10px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 19px 10px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  upperWrapper: {
    padding: '0 24px',
    overflowY: 'auto',
    height: '100%',
  },
}));

const Nav: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useTracking();

  const { account, transactions } = useAppSelector(state => ({
    account: state.shops.account,
    transactions: state.transactions?.transactions,
  }));

  const onLogoClick = () => {
    history.push('/');
  };

  const openedOrderCount = transactions?.filter(tx => backendStateToFrontendState(tx, 'order') === 'open').length;
  const newQuotes = transactions?.filter(
    tx => tx.order?.type === 'custom' && backendStateToFrontendState(tx, 'quote') === 'new',
  );
  const newQuoteCount = newQuotes?.length || 0;

  return (
    <>
      <Grid className={classes.upperWrapper} container direction="column" wrap="nowrap">
        <ButtonBase focusRipple onClick={onLogoClick} className={classes.logoContainer}>
          <Logo width={134} style={{ marginRight: 19 }} />
        </ButtonBase>
        <List disablePadding>
          {showFirstMonthPromoBlock(account) ? (
            <PromoBlock location="side-nav" />
          ) : (
            /* Cello Referral */
            <FeatureFlag name="feature_cello_referrals">
              <NavItem
                neverActive
                className="celloLauncher"
                label="Get 1 Month Free"
                Icon={GiftOutlinedIcon}
                iconClass={classes.giftIcon}
                path={location.pathname}
                onClick={() => {
                  trackEvent('Referral CTA Clicked', { location: 'side-nav' });
                }}
              />
            </FeatureFlag>
          )}
          {/* Home */}
          <NavItem label="Home" path="/" Icon={HomeOutlined} />
          {/* Overview */}
          <Typography className={classes.caption} component="p" variant="caption">
            Overview
          </Typography>
          <NavItem
            feature="admin.orders"
            label="Orders"
            path="/orders"
            Icon={ShoppingCartOutlined}
            itemCount={openedOrderCount || 0}
          />
          <NavItem
            feature="admin.quotes"
            label="Quotes & Invoices"
            path="/quotes"
            Icon={RequestQuoteOutlined}
            itemCount={newQuoteCount}
          />
          {account.tier?.name !== 'Unpaid' && (
            <NavItem label="Calendar" path="/calendar" Icon={CalendarIcon} feature="admin.calendar" proFeature />
          )}
          <NavItem feature="admin.analytics" label="Analytics" path="/analytics" Icon={PieChartOutlined} />
          {/* Marketing */}
          <Typography className={classes.caption} component="p" variant="caption">
            Marketing
          </Typography>
          <NavItem feature="admin.marketing" label="Email" path="/marketing/email" Icon={MailOutlinedIcon} />
          <NavItem feature="admin.marketing" label="SMS" path="/marketing/sms" Icon={ChatBubbleOutlineOutlinedIcon} />
          <NavItem
            feature="admin.marketing"
            label="Automations"
            path="/marketing/automations"
            Icon={OfflineBoltOutlinedIcon}
            betaChip
          />
          {/* <NavItem feature="admin.marketing" label="Campaigns" path="/marketing/campaigns" Icon={LanguageOutlinedIcon} newChip /> */}
          <NavItem
            feature="admin.marketing"
            label="Popups"
            path="/marketing/popups"
            Icon={NotificationsNoneOutlinedIcon}
          />
          <NavItem feature="admin.marketing" label="Social" path="/marketing/social" Icon={CropOriginalOutlinedIcon} />
          {/* Online Ordering */}
          <Typography className={classes.caption} component="p" variant="caption">
            Online Ordering
          </Typography>
          <NavItem feature="admin.products" label="Products" path="/products" Icon={CookieOutlinedIcon} />
          <NavItem feature="admin.products" label="Order Forms" path="/order-forms" Icon={ListAltOutlinedIcon} />
          <NavItem feature="admin.products" label="Events" path="/events" Icon={ConfirmationNumberOutlinedIcon} />
          <NavItem label="Fulfillments" path="/store/fulfillment" Icon={DriveEtaOutlined} />
          <FeatureFlag name="feature_presales">
            <NavItem feature="admin.presales" label="Presales" path="/presales" Icon={StorefrontOutlinedIcon} />
          </FeatureFlag>
          {/* Website/Shop */}
          <Typography className={classes.caption} component="p" variant="caption">
            Shop
          </Typography>
          <NavItem label="Appearance" path="/store/appearance" Icon={AppearanceIcon} />
          <NavItem feature="shop.subpages" label="Pages" path="/store/pages" Icon={DashboardOutlinedIcon} />
          <NavItem feature="admin.contacts" label="Contacts" path="/contacts/overview" Icon={PeopleOutlineOutlined} />
          <NavItem label="Settings" path="/store/dashboard" Icon={SettingsOutlined} />
        </List>
      </Grid>

      <Hidden smDown>
        <AvatarMenu
          variant="large"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        />
      </Hidden>
    </>
  );
};

export default Nav;
