import Dinero from 'dinero.js';
import _ from 'lodash';
import { default as calculateStripeFees } from 'stripe-fees';
import { Fees, TransactionTotals } from '../transaction';
import { Coupon } from '../coupon';
import { PaymentSettings } from '../shop';
import { LineItem, Order, OrderType } from '../order';

const stripePercentage = 2.9;
const stripeFixedFee = 30;

export function calculateTransactionFees(
  orderType: OrderType,
  totalInCents: number,
  tip: number,
  paymentSettings: PaymentSettings,
): Fees {
  const {
    castironTakeRate,
    takeRateLevels,
    customerRate: customerRateSetting,
    isCustomerPayingStripeFee,
  } = paymentSettings;

  const levelForOrder = _.reduceRight(
    _.sortBy(
      takeRateLevels?.filter(l => l.type === orderType || l.type === 'all'),
      l => l.castironTakeRate,
    ),
    (rate, l) => {
      console.debug(`${l.orderTotalMinimum} > ${totalInCents} ? ${rate} : ${l.castironTakeRate}`);
      return l.orderTotalMinimum > totalInCents ? rate : l.castironTakeRate;
    },
    castironTakeRate,
  );
  console.debug(`Using Take Rate: [${levelForOrder}]`);
  console.debug(`Castiron Take Rate: [${castironTakeRate}]`);

  const customerRaterPercentage = castironTakeRate > 0 ? customerRateSetting / castironTakeRate : 0;
  console.debug(`customerRaterPercentage: [${customerRaterPercentage}]`);

  const artisanRatePercentage = 1 - customerRaterPercentage;

  console.debug(`Artisan Rate % [${artisanRatePercentage}]`);

  const artisanRate = levelForOrder !== 0 ? levelForOrder * artisanRatePercentage : 0;
  const customerRate = levelForOrder !== 0 ? levelForOrder * customerRaterPercentage : 0;

  if (totalInCents === 0) {
    return {
      stripeFees: 0,
      castIronFees: 0,
      totalFees: 0,
      totalArtisanFees: 0,
      totalCustomerFees: 0,
      artisanFeePercent: artisanRate,
      customerFeePercent: customerRate,
      customerPaidStripeFees: isCustomerPayingStripeFee,
      applicationFees: 0,
      castironCustomerFees: 0,
      castironArtisanFees: 0,
      takeRate: levelForOrder,
    };
  }

  const total = Dinero({ amount: totalInCents });
  const totalWithTip = Dinero({ amount: totalInCents + tip });
  const totalCastIronFees = total.multiply(levelForOrder / 100);
  let customerCastironFees = total.multiply(customerRate / 100);
  let artisanCastironFees = total.multiply(artisanRate / 100);
  let stripeFees = 0;
  let totalFees = 0;
  let totalCustomerFees = 0;
  let totalArtisanFees = 0;
  let applicationFees = 0;

  const sFixedFee = Dinero({ amount: stripeFixedFee });
  const stripeFeeDenom = 1 - stripePercentage / 100;

if (artisanRate !== levelForOrder) {
    const grossTotal = totalWithTip
      .add(customerCastironFees)
      .add(sFixedFee)
      .divide(stripeFeeDenom);

    stripeFees = grossTotal
      .subtract(customerCastironFees)
      .subtract(totalWithTip)
      .getAmount();
    totalFees = stripeFees + customerCastironFees.getAmount();
    totalCustomerFees = totalFees;
    totalArtisanFees = totalCastIronFees.subtract(customerCastironFees).getAmount();
    applicationFees = stripeFees + totalCastIronFees.getAmount();
  } else if ((artisanRate === 0 && customerRate !== 0) || isCustomerPayingStripeFee) {
    const grossTotal = totalWithTip
      .add(totalCastIronFees)
      .add(sFixedFee)
      .divide(stripeFeeDenom);

    stripeFees = grossTotal
      .subtract(totalCastIronFees)
      .subtract(totalWithTip)
      .getAmount();
    totalFees = stripeFees + customerCastironFees.getAmount();
    totalCustomerFees = totalFees;
    applicationFees = totalCustomerFees;
  } else {
    console.debug(`Stripe Fee Calc Params: (${totalInCents}, ${customerRate}, ${isCustomerPayingStripeFee})`);

    const fees = calculateStripeFees(totalInCents + tip, 'US', 'US', customerRate, false);

    console.debug('Calculated Fees', fees);

    stripeFees = fees.providerFeeAmount;
    customerCastironFees = Dinero({ amount: fees.applicationFeeAmount });
    artisanCastironFees = totalCastIronFees.subtract(Dinero({ amount: fees.applicationFeeAmount }));
    totalFees = fees.providerFeeAmount + totalCastIronFees.getAmount();
    totalCustomerFees = isCustomerPayingStripeFee ? fees.totalFeeAmount : 0;
    totalArtisanFees = totalFees - totalCustomerFees;
    applicationFees = totalFees;
  }

  return {
    stripeFees,
    castIronFees: totalCastIronFees.getAmount(),
    totalFees,
    totalArtisanFees,
    castironArtisanFees: artisanCastironFees.getAmount(),
    totalCustomerFees,
    castironCustomerFees: customerCastironFees.getAmount(),
    artisanFeePercent: artisanRate,
    customerFeePercent: customerRate,
    customerPaidStripeFees: isCustomerPayingStripeFee,
    applicationFees,
    takeRate: levelForOrder,
  };
}

export function calculateTax(subtotal: number, taxRate = 0): number {
  const tax = Dinero({ amount: subtotal })
    .percentage(taxRate)
    .getAmount();

  console.debug(`tax: [${tax}]`);
  return tax;
}

/**
 * If this function seems confusing, that's because it is. The relationship between Products/LineItem, Selections and Sub LineItems
 * is NOT good. Basically, because the way we have had to hack in Quotes, Sub LineItems are actually peers of Products/LineItems, not
 * subcomponents of them as their name would imply. So the calculation below does look incorrect if you are expecting
 * Sub LineItems to contribute to the unit price of a LineItem, but as it stands now, they DON'T. Instead they are really
 * independent LineItems that are stuffed inside a Product LineItem to make Quotes work.
 *
 * Selections DO affect the unit price of a LineItem though. So ya, clear as mud this.
 *
 * This all needs to get fixed the next time we expand our order/transaction model or everything will fall apart.
 *
 * @param items
 */
export function calculateSubtotal(items: LineItem[]): number {
  let total = 0;
  items.forEach(item => {
    const selectionTotal =
      item.selections &&
      item.selections
        .map(s => _.reduce(s.selectedValues, (s1, s2) => s1 + (s2.cost || 0), 0))
        .reduce((a, b) => a + b, 0);

    const productTotal = (item.price || 0) + (selectionTotal || 0);
    const productSubtotal = Dinero({ amount: productTotal })
      .multiply(item.quantity || 1)
      .getAmount();

    const subLineItemTotal = item.subLineItems && item.subLineItems.reduce((i1, i2) => i1 + i2.price * i2.quantity, 0);

    total += productSubtotal + (subLineItemTotal || 0);
  });

  return total;
}

function calculateSubTotalsWithFulfillment(subtotal: number, fulfillmentFee: number) {
  return Dinero({ amount: subtotal })
    .add(Dinero({ amount: fulfillmentFee }))
    .getAmount();
}

export interface CartCalculationOptions {
  order: Order;
  paymentSettings: PaymentSettings;
  coupon?: Coupon;
  tip?: number;
  tippingPresetPercentage?: number;
}

export function calculateTotals({
  order,
  paymentSettings,
  coupon = undefined,
  tip = 0,
  tippingPresetPercentage = undefined,
}: CartCalculationOptions): TransactionTotals {
  const { taxRate } = paymentSettings;
  const taxRateInt = typeof taxRate === 'string' ? parseInt(taxRate, 10) : taxRate;
  const totals: TransactionTotals = {
    subtotal: 0,
    taxes: 0,
    stripeFees: 0,
    castIronFees: 0,
    totalFees: 0,
    fulfillmentFee: order.fulfillmentOption?.fee || 0,
    totalWithTax: 0,
    totalWithoutFees: 0,
    totalWithoutFeesOrTip: 0,
    total: 0,
    subTotalsWithFulfillment: 0,
    coupon: 0,
    subTotalWithCoupon: 0,
    totalCustomerFees: 0,
    totalArtisanFees: 0,
    tip: tip || 0,
    tippingPresetPercentage: tippingPresetPercentage || 0,
  };

  // Sub Total
  totals.subtotal = calculateSubtotal(order.items);
  totals.subTotalWithTip = totals.subtotal + tip;
  console.debug(`totals.subtotal: [${totals.subtotal}]`);
  console.debug(`totals.fulfillmentFee: [${totals.fulfillmentFee}]`);

  console.debug('coupon: ', coupon);
  // Coupon
  if (coupon) {
    if (coupon.discount.type === 'amount') {
      totals.coupon = coupon.discount.value;
    } else {
      totals.coupon = Math.round((coupon.discount.value / 100) * totals.subtotal);
    }
  }

  totals.subTotalWithCoupon = Dinero({ amount: totals.subtotal })
    .subtract(Dinero({ amount: totals.coupon }))
    .getAmount();

  if (totals.subTotalWithCoupon < 0) totals.subTotalWithCoupon = Dinero({ amount: 0 }).getAmount();

  console.debug(`totals.subTotalWithCoupon: [${totals.subTotalWithCoupon}]`);
  totals.subTotalsWithFulfillment = calculateSubTotalsWithFulfillment(totals.subTotalWithCoupon, totals.fulfillmentFee);

  console.debug(`subTotalsWithFulfillment: [${totals.subTotalsWithFulfillment}]`);

  // Taxes
  totals.taxes = calculateTax(totals.subTotalsWithFulfillment, taxRateInt);

  // Transaction Fees
  totals.totalWithTax = Dinero({ amount: totals.subTotalsWithFulfillment })
    .add(Dinero({ amount: totals.taxes }))
    .getAmount();

  const fees = calculateTransactionFees(order.type, totals.totalWithTax, totals.tip, paymentSettings);
  console.debug(`Fees`, fees);

  // Totals
  totals.totalWithoutFees = totals.totalWithTax + totals.tip;
  totals.totalWithoutFeesOrTip = totals.totalWithTax;

  totals.total = Dinero({ amount: totals.totalWithTax })
    .add(Dinero({ amount: fees.totalCustomerFees }))
    .add(Dinero({ amount: totals.tip }))
    .getAmount();

  const result = {
    ...totals,
    ...fees,
  };

  return result;
}
