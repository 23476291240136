import React, { useEffect, useState } from 'react';
import { Box, Card, CardMedia, Dialog, DialogContent, Grid, IconButton, Theme } from '@material-ui/core';
import { CloseIcon, SearchIcon } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel'
import Typography from '../Typography';
import ContentBox from '../ContentBox';
import { SelectedAsset } from '@castiron/domain';

export interface CarouselImage {
  id: string,
  title: string,
  fullAsset: SelectedAsset,
  notes: string,
  number: number,
}

type Props = {
  images: SelectedAsset[];
};

const useStyles = makeStyles((theme: Theme) => ({
  activeThumbnail: {
    borderRadius: '4px',
  },
  body: {
    padding: '32px',
  },
  box: {
    marginBottom: 24,
  },
  cardContainer: {
    height: 120,
    width: 120,
    margin: 20,
  },
  carouselContainer: {
    margin: '8px 0',
    backgroundColor: theme.branding.gray[100],
  },
  carouselCardContainer: {
    border: 'none',
    boxShadow: 'none',
    margin: '8px 0',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.branding.gray[100],
  },
  carouselImage: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  carouselHeader: {
    marginTop: 10,
  },
  image: {
    height: 120,
    width: 120,
  },
  imageContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    margin: '20px 0',
  },
  imageNotes: {
    padding: 24,
    paddingNotes: 0,

    '& h4': {
      paddingBottom: 5,
    },
  },
  imageNotesShort: {
    overflow: 'hidden',
    // weird text truncating thing, but it works
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  searchIcon: {
    backgroundColor: theme.branding.gray[100],
    borderRadius: 4,
    position: "absolute",
    bottom: "5px",
    right: "5px",
    zIndex: 2,
  },
  thumbnailContainer: {
    display: 'flex',
  },
  thumbnailImage: {
    height: 50,
    marginTop: 15,
    marginRight: 8,
    width: 80,
    objectFit: 'cover',
  },
  title: {
    fontSize: '10pt',
    fontWeight: 'bold',
    paddingTop: 12,
    paddingLeft: 12,
  },
  viewImage: {
    color: theme.branding.blue.primary,
    cursor: 'pointer',
    marginTop: 35,
    textDecoration: 'none',
  },
}));

const PhotoListAndGallery: React.FC<Props> = (props: Props) => {
  const { images } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [photoNumber, setPhotoNumber] = useState(0);
  const [displayNumber, setDisplayNumber] = useState(0);
  const [carouselImages, setCarouselImages] = useState<CarouselImage[]>([]);

  useEffect(() => {
    const carouselImages: CarouselImage[] = images.map((image, index) => ({
      id: image.id,
      title: image.title,
      fullAsset: image,
      notes: image.notes,
      number: index + 1,
    }));
    setCarouselImages(carouselImages);
  }, [images]);

  const handleOpen = (number) => {
    setPhotoNumber(number);
    setDisplayNumber(++number);
    setOpen(true);
  };

  const handleClose = () => {
    setPhotoNumber(0);
    setDisplayNumber(1);
    setOpen(false);
  };

  const handleImageError = (e, image) => {
    const target = e.target as any;
    target.onerror = null;
    target.src = image.fullAsset.asset.downloadUrl;
  };

  function Image(image) {
    return (
      <Card className={classes.carouselCardContainer}>
        <CardMedia
          className={classes.carouselImage}
          component="img"
          image={image.image.fullAsset.asset.mediumVersion.downloadUrl}
          alt={image.image.notes}
          onError={(e) => handleImageError(e, image.image)}
        />
      </Card>
    );
  }

  function Thumbnail(images) {
    return (
      <Grid container className={classes.thumbnailContainer}>
        {images.image.map((image, index) => <img onClick={() => handleOpen(index)} className={`${classes.thumbnailImage} ${index === photoNumber ? classes.activeThumbnail : ''}`} src={image.fullAsset.asset.smallVersion.downloadUrl} onError={(e) => handleImageError(e, image)} />)}
      </Grid>
    );
  }

  return (
    <>
      {
        carouselImages.map((image: CarouselImage, index) =>
          <Grid className={classes.imageContainer} key={image.id}>
            <Grid container>
              <Grid item xs={7} className={classes.imageNotes}>
                <Typography variant="h4">Photo #{image.number}</Typography>
                <Grid container direction="column" justify='space-between'>
                  <Typography variant="body2" className={classes.imageNotesShort}>Customer notes: {image.notes || 'None'}</Typography>
                  <a className={classes.viewImage} onClick={() => handleOpen(index)}>View Details</a>
                </Grid>
              </Grid>
              <Grid container item xs={5} justify="flex-end">
                <Card className={classes.cardContainer} style={{ position: "relative" }}>
                  <CardMedia
                    className={classes.image}
                    component="img"
                    image={image.fullAsset.asset.smallVersion.downloadUrl}
                    alt={image.notes}
                    onClick={() => handleOpen(index)}
                    style={{ position: "relative" }}
                    onError={(e) => handleImageError(e, image)}
                  />
                  <IconButton
                    size="small"
                    aria-label="magnify"
                    color="inherit"
                    className={classes.searchIcon}
                    onClick={() => handleOpen(index)}
                  >
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Grid xs={12} className={classes.carouselContainer} container item justify="center" spacing={4}>
            <Grid container direction="row" justify="flex-end">
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <Carousel
                autoPlay={false}
                index={photoNumber}
                navButtonsAlwaysVisible={true}
                indicators={false}
                next={(next) => handleOpen(next)}
                prev={(prev) => handleOpen(prev)}>
                {
                  carouselImages.map((image: CarouselImage, index) => <Image image={image} key={image.id} />)
                }
              </Carousel>
              <Thumbnail image={carouselImages} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className={classes.carouselHeader} variant="h1" component="h3">Photo #{displayNumber}</Typography>
              <Typography variant="body1">Customer notes: {carouselImages[photoNumber]?.notes || 'None'}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PhotoListAndGallery;
