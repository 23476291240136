import React, {useContext, useState} from "react";
import {Grid, Input, Theme} from "@material-ui/core";
import {Button, NumberInput} from "@castiron/components";
import {AttributionContext, AttributionData} from "@castiron/utils";
import {getService} from "../../firebase";
import {pricequote} from "@castiron/functions/src/orders";

const priceQuoteService = getService('orders', 'pricequote');

interface Props {

}


const TotalsApi: React.FC<Props> = (props: Props) => {
  const {  } = props;
  const [amount, setAmount] = useState<number>();
  const [result, setResult] = useState<string>();

  const { attribution } = useContext<AttributionData>(AttributionContext);

  const onSubmit = () => {
    priceQuoteService({
      attribution,
      amount,
    }).then((r) => {
      setResult(JSON.stringify(r));
    }).catch(err => {
      setResult('Error!');
      console.error('Error checking out', err);
    })
  }

  return <Grid container direction="column">
    <Grid item container direction="row">
      <Grid item xs={8}><strong>Amount: </strong></Grid>
      <Grid item xs={4}><Input onChange={v => setAmount(parseFloat(v.target.value))}/></Grid>
    </Grid>
    <Grid item container direction="row">
      <Grid item xs={12}><Button onClick={onSubmit}>Quote</Button></Grid>
    </Grid>
    <Grid item xs={12}>{result}</Grid>
  </Grid>;
}

export default TotalsApi;
