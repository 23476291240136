import { ButtonV2, CollapsableCard, Typography } from '@castiron/components';
import { defaultTimeZone, download, useTracking } from '@castiron/utils';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Dinero from 'dinero.js';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getService } from '../../../../firebase';
import { useAppSelector } from '../../../../hooks';
import Spinner from '../../../Spinner';

const searchService = getService('orders', 'search');
const exportOrdersByProductService = getService('orders', 'exportordersbyproduct', { type: 'request' });

type Props = {
  context: 'product' | 'presale';
  id: string;
  isEvent?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
}));

const ProductInsightTile: React.FC<Props> = (props: Props) => {
  const { context, id, isEvent } = props;

  const classes = useStyles();
  const { trackEvent } = useTracking();
  const [unitsSold, setUnitsSold] = useState<number>(0);
  const [numberOfUniqueCustomers, setNumberOfUniqueCustomers] = useState<number>(0);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const getUnitsSold = (orders) => {
    return orders?.reduce((totalQuantity, order) => {
      return totalQuantity + order?.items?.reduce((orderQuantity, product) => {
        if (context === 'product' && product?.productId !== id) return orderQuantity + 0;
        return orderQuantity + (product?.quantity || 0);
      }, 0);
    }, 0);
  };

  const search = async () => {
    if (id) {
      const results = await searchService({
        stage: 'order',
        ...(context === 'presale' ? { presaleId: id } : { productId: id }),
        transactionStatus: 'succeeded',
      });
      setUnitsSold(getUnitsSold(results.orders));
      setNumberOfUniqueCustomers(_.uniqBy(results.orders, 'customerId').length);
      setTotalSales(results.totalSales);
    }
  };

  useEffect(() => {
    search();
  }, []);

  const downloadReport = async () => {
    setIsDownloadingReport(true);

    const csv = await exportOrdersByProductService({
      context,
      id,
      timeZone: shop?.config?.timeZone || defaultTimeZone,
    });

    const date = moment().format('MMDDYYYY');
    const filename = `${shop.websiteUrl}-orders-by-${context}-${date}.csv`;

    download(filename, 'text/csv', csv);

    trackEvent(`Orders by ${_.capitalize(context)} Report Downloaded`);

    setIsDownloadingReport(false);
  };

  return (
    <>
      <Spinner show={isDownloadingReport} size={'fullscreen'} label="Gathering your data…" />
      <CollapsableCard defaultExpanded noScroll title={isEvent ? "Reports" : "Insights"}>
        <Typography variant="body1">Sold {unitsSold} {isEvent ? 'ticket' : 'unit'}{unitsSold === 1 ? '' : 's'} to {numberOfUniqueCustomers} customer{numberOfUniqueCustomers === 1 ? '' : 's'} for {Dinero({ amount: totalSales || 0 }).toFormat('$0.00')} in revenue.</Typography>
        <ButtonV2
          onClick={downloadReport}
          style={{ padding: 0 }}
          variant="text"
        >
          Download{isEvent ? " Event " : " "}Report
        </ButtonV2>
      </CollapsableCard>
    </>
  );
};

export default ProductInsightTile;
