export type TemplateType =
  | '4th-of-july-announcement'
  | '4th-of-july-customs'
  | '4th-of-july-reminder'
  | '4th-of-july-engagement'
  | '4th-of-july-shop'
  | '4th-of-july-last-call'
  | 'fathers-day-announcement'
  | 'fathers-day-customs'
  | 'fathers-day-reminder'
  | 'fathers-day-engagement'
  | 'fathers-day-shop'
  | 'fathers-day-last-call'
  | 'graduation-announcement'
  | 'graduation-reminder'
  | 'graduation-feature'
  | 'graduation-last-call'
  | 'back-to-school-announcement'
  | 'back-to-school-reminder'
  | 'back-to-school-shop'
  | 'back-to-school-customs'
  | 'back-to-school-last-call'
  | 'sms-signup'
  | 'new-product'
  | 'custom-order-available'
  | 'new-presale';

export interface Template {
  eventName?: string;
  eventTag?: string;
  pageTitle: string;
  pageTitleMobile?: string;
  step1TitleUnselected: string;
  step1TitleSelectedPrefix?: string;
  subjectLine: string;
  bodyText: string;
  buttonText: string;
  postSendModalTitlePrefix: string;
  trackEventName: string;
  showCustomProductsOnly: boolean;
}

export const templates = {
  '4th-of-july-announcement': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Announcement',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '🎉 Celebrate July 4th with a Dash of Sweetness!',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Are you geared up for a patriotic celebration filled with scrumptious delights? 🎆 Make this July 4th truly unforgettable with our mouthwatering small batch treats!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Announcement',
    showCustomProductsOnly: false,
  },
  '4th-of-july-customs': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Customs',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Custom Goods for Independence Day!',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Looking to add a personal touch to your July 4th celebration? My shop is now accepting custom orders for small-batch artisanal foods tailored to your patriotic party theme!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Customs',
    showCustomProductsOnly: true,
  },
  '4th-of-july-reminder': {
    eventName: "Father's Day",
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Reminder',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'A Burst of Patriotic Flavor! 🧨',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Are you prepared to add a dash of All-American sweetness to your July 4th festivities? ✨ Make this Independence Day one to remember with delectable artisanal goodies!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Reminder',
    showCustomProductsOnly: false,
  },
  '4th-of-july-engagement': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Engagement',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '🎇 Elevate Your July 4th Celebration!',
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hi there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>As July 4th approaches, it's time to plan your festivities with some delectable treats! My online shop is stocked with an array of mouthwatering goods that are perfect for adding a memorable touch to your Independence Day celebration.</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Engagement',
    showCustomProductsOnly: false,
  },
  '4th-of-july-shop': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Shop',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: "🎇 Time's Ticking! Ready for July 4th?",
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hello there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Just as fast as the fireworks fizzle - don't miss out on these stellar foods! Place your order now to ensure delivery in time for July 4th festivities. Time's running out!</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Shop',
    showCustomProductsOnly: false,
  },
  '4th-of-july-last-call': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Last Call',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '🎆 Last Call for July 4th Orders!',
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hi there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Don't miss out on adding some extra sweetness to your July 4th holiday - place your order now before it's too late!</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Last Call',
    showCustomProductsOnly: false,
  },
  'fathers-day-announcement': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Announcement',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Father’s Day: Are You Ready?',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Are you ready for a dose of special sweetness for the dads and dad figures in your life? ⛳ Make Father’s Day one to remember with delicious small batch goodies!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Announcement',
    showCustomProductsOnly: false,
  },
  'fathers-day-customs': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Customs',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Dad = Too Sweet! Are you ready?',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Time is running out! Be sure to get your custom orders in time for Father’s Day on June 16th. For best results, we recommend a June 9th deadline!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Give your dad, brother, husband, grandfather, stepdad, or dog dad a memorable Father’s Day with specialty foods!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Customs',
    showCustomProductsOnly: true,
  },
  'fathers-day-reminder': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Reminder',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'How Sweet Father’s Day Is!',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Just checking in - do you have something thoughtful planned for the father in your life? Nothing says indulgence like heartfelt creations. Ties collect dust, golf clubs sit in storage, but great baked goods make great memories and are a unique and thoughtful gift! 🍪</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Reminder',
    showCustomProductsOnly: false,
  },
  'fathers-day-engagement': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Engagement',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Spoil Dad this Father’s Day!',
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hi there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>As Father's Day approaches, we're excited to offer you the perfect opportunity to show your love and appreciation for all the dads and dad-figures in your life!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>That's why we've curated a delightful selection of artisanal food goods that are sure to make his day memorable.</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Engagement',
    showCustomProductsOnly: false,
  },
  'fathers-day-shop': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Shop',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: "Save the Day with Treats for Father's Day!",
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hello there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>With Father's Day fast approaching, are you feeling the pressure to find the perfect gift?</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Fear not! We're here to save the day with our lineup of artisanal delights tailored to make every dad feel like a superhero!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>It's time to soar into action and secure your order before Father's Day arrives in less than a week!</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Shop',
    showCustomProductsOnly: false,
  },
  'fathers-day-last-call': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Last Call',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Super Dad = Super Treats!',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Time’s a-ticking! 🕓 do you have your gifts ready for Father’s Day?</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Have no fear, artisanal foods are here! We’re your super heroes, offering unique culinary experiences for the dads and dad-figures in your life!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Time to swoop in and place your order since Father’s Day is less than one week away!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Last Call',
    showCustomProductsOnly: false,
  },
  'graduation-announcement': {
    eventName: 'Graduation',
    eventTag: 'Graduation',
    pageTitle: 'Graduation Announcement Email',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Time for Pomp and Circumstance! 🎓',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Graduation season is upon us! Don’t miss the grade - or the opportunity to celebrate the grad in your life with delicious artisanal goodies!</span></p>`,
    buttonText: 'Shop Graduation',
    postSendModalTitlePrefix: 'Graduation Email',
    trackEventName: 'Graduation Announcement',
    showCustomProductsOnly: false,
  },
  'graduation-reminder': {
    eventName: 'Graduation',
    eventTag: 'Graduation',
    pageTitle: 'Graduation Reminder Email',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Make Grad Parties Memorable! 🎉',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">We bet you have at least one grad party on the books this spring - whether it's your own family member’s, friend’s, neighbor’s, or heck, maybe it’s your own!</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Graduation happens across all ages and walks of life - from kindergarten all the way to a doctorate! 🎓</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Make the occasion memorable with delicious and eye-catching creations!</span></p>
    `,
    buttonText: 'Shop Graduation',
    postSendModalTitlePrefix: 'Graduation Email',
    trackEventName: 'Graduation Reminder',
    showCustomProductsOnly: false,
  },
  'graduation-feature': {
    eventName: 'Graduation',
    eventTag: 'Graduation',
    pageTitle: 'Graduation Feature Email',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Time is Running Out!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Time is running out to make the grade 📝 with custom artisanal creations!</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Get your orders in before the sun sets on graduation season!</span></p>
    `,
    buttonText: 'Shop Graduation',
    postSendModalTitlePrefix: 'Graduation Email',
    trackEventName: 'Graduation Feature',
    showCustomProductsOnly: false,
  },
  'graduation-last-call': {
    eventName: 'Graduation',
    eventTag: 'Graduation',
    pageTitle: 'Graduation Last Call Email',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Extra Credit: Let’s Get Ordered! ',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Tick-tock! The graduation clock is winding down, and we don't want you to miss out on your slice of the celebration cake (or cupcakes, or cookies... you get the idea)!</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">So, what are you waiting for? Don't let procrastination be your major!</span></p>
    `,
    buttonText: 'Shop Graduation',
    postSendModalTitlePrefix: 'Graduation Email',
    trackEventName: 'Graduation Last Call',
    showCustomProductsOnly: false,
  },
  'back-to-school-announcement': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Announcement',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '📣 Delicious and Memorable School Year Ahead!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Pencils are packed, crayons are organized, backpacks are by the door. Forgetting something? Set the tone for the new school year with our mouthwatering goodies!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Announcement',
    showCustomProductsOnly: false,
  },
  'back-to-school-reminder': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Reminder',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Tick-tock, Back-to-School is Approaching!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">The final bell is about to ring - don’t miss out on ordering back-to-school favorites today! ⌚</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Reminder',
    showCustomProductsOnly: false,
  },
  'back-to-school-shop': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Shop',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Apples = Old News',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Bringing teachers an apple is so old-school - kick things up a notch with small-batch goodies this back-to-school season!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Shop',
    showCustomProductsOnly: false,
  },
  'back-to-school-customs': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Customs',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Three Cheers for Custom Goodies!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Rah, rah! 📣 Sports games and school spirit go hand-in-hand! Gear up for events with custom goodies in team colors to embrace upcoming festivities!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Customs',
    showCustomProductsOnly: true,
  },
  'back-to-school-last-call': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Last Call',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Last Call: The final bell is about to ring 🔔',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Don’t let time run out! Order your back-to-school favorites today and create wholesome memories.</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Last Call',
    showCustomProductsOnly: false,
  },
  'sms-signup': {
    pageTitle: 'Sign Up for SMS',
    pageTitleMobile: 'Sign Up for SMS',
    step1TitleUnselected: '',
    subjectLine: 'Sign Up for Exclusive SMS Updates!',
    bodyText:
      "<p>Hi there!</p><p><br></p><p>Stay in the loop with our latest offerings and exclusive promotions by joining our new SMS list! Opt-in today to receive instant updates right to your phone.</p><p><br></p><p>Don't miss out—be the first to know!</p>",
    buttonText: 'Sign Up',
    postSendModalTitlePrefix: 'Sign Up for SMS Email',
    trackEventName: 'SMS Signup',
    showCustomProductsOnly: false,
  },
  'new-product': {
    pageTitle: 'Product Announcement Email',
    pageTitleMobile: 'Announcement',
    step1TitleUnselected: 'Product to Announce',
    subjectLine: 'A New Product Just Dropped!',
    bodyText:
      'We are excited to announce our latest product! Click on the button below to learn more and place an order.',
    buttonText: 'Order Now',
    postSendModalTitlePrefix: 'Product Announcement',
    trackEventName: 'Product Announcement',
    showCustomProductsOnly: false,
  },
  'custom-order-available': {
    pageTitle: 'New Availability Email',
    pageTitleMobile: 'Availability',
    step1TitleUnselected: 'Which Product Does This Apply To?',
    step1TitleSelectedPrefix: 'New Dates For',
    subjectLine: 'New Dates Available For Custom Orders!',
    bodyText:
      'I just opened up new dates on my calendar. Fill out a request at the link below to request a custom order and secure a date on my calendar!',
    buttonText: 'Submit a Request',
    postSendModalTitlePrefix: 'Availability Announcement',
    trackEventName: 'Custom Order Availability',
    showCustomProductsOnly: true,
  },
  'new-presale': {
    pageTitle: 'Presale Announcement Email',
    pageTitleMobile: 'Announcement',
    step1TitleUnselected: 'Presale To Announce',
    subjectLine: 'The Presale You’ve Been Waiting for is Live!',
    bodyText:
      'We are excited to announce our newest presale is live! Click on the button below to see everything we have available and shop the presale while you can.',
    buttonText: 'Shop the Presale',
    postSendModalTitlePrefix: 'Presale Announcement',
    trackEventName: 'Presale Announcement',
    showCustomProductsOnly: false,
  },
};
