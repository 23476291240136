import React, { Dispatch, ReactElement, SetStateAction } from 'react';
/* eslint-disable react/display-name */
import { Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GridCellParams, GridColumns } from '@material-ui/data-grid';
import DataGrid from '../DataGrid/DataGrid';
import { useHistory } from 'react-router';
import Dinero from 'dinero.js';
import moment from 'moment';
import _ from 'lodash';
import { defaultTimeZone } from '@castiron/utils';
import { Checkbox, OrderStatusPill, Typography } from '@castiron/components';
import { backendStateToFrontendState, Transaction } from '@castiron/domain';
import { useAppSelector } from '../../hooks';
import { number } from 'yup';

type Props = {
  selectedOrders: Transaction[];
  total?: number;
  transactions: Transaction[];
  page?: number;
  setSelectedOrders: Dispatch<SetStateAction<Transaction[]>>;
  retrieveOrders?: (page: number, searchText?: string) => Promise<void>;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
  },
  dateTime: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerCheckbox: {
    marginTop: 10,
  },
}));

const OrdersList: React.FC<Props> = (props: Props) => {
  const { transactions, selectedOrders, total, page, setSelectedOrders, retrieveOrders } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXtraSmallMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { customers, shop } = useAppSelector(state => ({
    customers: state.customers.customers,
    shop: state.shops.shop,
  }));

  const tz = shop.config?.timeZone || defaultTimeZone;

  const getStatusStyles = status => {
    const styles: React.CSSProperties = {
      color: 'black',
      fontWeight: 700,
      textTransform: 'uppercase',
      textOverflow: 'ellipsis',
    };
    switch (status) {
      case 'completed':
        styles.color = theme.branding.green.primary;
        break;
      case 'canceled':
        styles.color = theme.palette.error.main;
        break;
      case 'open':
        styles.color = theme.palette.warning.main;
        break;
      case 'fulfilled':
        styles.color = theme.branding.v2.blue[800];
        break;
    }
    return styles;
  };

  const columns: GridColumns = [
    {
      field: 'customer',
      headerName: 'Checkbox',
      flex: 0.5,
      sortable: false,
      renderCell: (params: GridCellParams): ReactElement => {
        const orderId = params.row.id as string;
        const isChecked = !!selectedOrders.find(o => o.id === orderId);
        return (
          <Checkbox
            checked={isChecked}
            onChange={(e, checked) => {
              setSelectedOrders(
                isChecked
                  ? selectedOrders.filter(o => o.id !== orderId)
                  : _.uniqBy([...selectedOrders, params.row as Transaction], 'id'),
              );
            }}
          />
        );
      },
      renderHeader: (params: GridCellParams): ReactElement => {
        const isChecked = transactions.every(t => !!selectedOrders.find(so => t.id === so.id));
        return (
          <Checkbox
            checked={isChecked}
            onChange={(e, checked) => setSelectedOrders(isChecked ? [] : transactions)}
            checkboxClass={classes.headerCheckbox}
          />
        );
      },
    },
    {
      field: 'id',
      headerName: 'Order #',
      flex: isMobile ? 0 : 0.8,
      valueGetter: (params: GridCellParams): any => {
        return params.row.order.orderNumber;
      },
    },
    {
      field: 'customerName',
      headerName: 'customer',
      flex: 1,
      valueGetter: (params: GridCellParams): any => {
        const customerId = params.row.customerObj?.id;
        if (!customerId) return 'None';

        const customer = customers.find(c => c.id === customerId);

        if (!customer?.firstName) {
          return customer?.email || 'None';
        }

        let name = !!customer && customer.firstName ? `${customer.firstName}` : `${params.row.customerName}`;
        if (!!customer && customer.lastName) name += ` ${customer.lastName}`;

        return name;
      },
    },
    {
      field: 'status',
      flex: 0.75,
      renderCell: (params: GridCellParams): ReactElement => {
        const transaction = params.row as Transaction;
        if (isXtraSmallMobile) {
          /* I would really like to refactor this to be a style of the chip, but time is not on my side tonight */
          return (
            <div style={getStatusStyles(backendStateToFrontendState(transaction, 'order'))}>
              {params.value === 'soldOut' ? 'Sold Out' : params.value.toString()}
            </div>
          );
        }

        return <OrderStatusPill transaction={transaction} context="order" />;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.75,
    },
    {
      field: 'dueDate',
      headerName: 'due date',
      flex: 1.5,
      renderCell: (params: GridCellParams) => {
        let date;
        let startTime;
        let endTime;

        if (
          params.row.order.fulfillmentOption?.schedule?.dates[0]?.startTime &&
          params.row.order.fulfillmentOption?.schedule?.type === 'fixed'
        ) {
          //for custom orders, 'fixed' refers to a specific time, whereas 'flexible' is a date but no time. for standard orders, it refers to having a date and time, whereas 'flexible' is no specific date or time. either way you need to display both date and time.

          date = moment
            .unix(params.row.order.fulfillmentOption.schedule.dates[0].startTime)
            .tz(tz)
            .format('MM/DD/YY');

          startTime = moment
            .unix(params.row.order.fulfillmentOption.schedule.dates[0].startTime)
            .tz(tz)
            .format('h:mma');

          endTime = moment
            .unix(params.row.order.fulfillmentOption.schedule.dates[0].endTime)
            .tz(tz)
            .format('h:mma');
        } else if (params.row.order.fulfillmentOption?.schedule?.dates[0]?.startTime) {
          //this should only apply for custom orders, since non-custom fulfillment options will always have a start and an end time

          date = moment
            .unix(params.row.order.fulfillmentOption.schedule.dates[0].startTime)
            .tz(tz)
            .format('MM/DD/YY');
        } else if (params.row.order.fulfillmentOption?.date) {
          date = moment
            .unix(params.row.order.fulfillmentOption.date)
            .tz(tz)
            .format('MM/DD/YY');
        }

        return (
          <Grid container direction="column" className={classes.dateTime}>
            <Typography variant="body2">{date}</Typography>
            <Typography variant="body2">{startTime && `${startTime} - ${endTime}`}</Typography>
          </Grid>
        );
      },
    },
    {
      field: 'total',
      headerName: 'total',
      flex: 0.9,
      align: isMobile ? 'right' : 'left',
      valueGetter: (params: GridCellParams): number => {
        return params.row.totals?.total || 0;
      },
      valueFormatter: (params: GridCellParams): string => {
        const total = params.row.totals?.total || 0;
        return Dinero({ amount: total }).toFormat('$0.00');
      },
    },
    {
      field: 'actions',
      renderHeader: (): ReactElement => <div />,
      flex: 0.4,
    },
  ];

  const capitalize = (val: string) => {
    return val[0].toUpperCase() + val.slice(1); // to sentence case the fulfillment option type
  };

  const onCellClick = (params: GridCellParams): void => {
    if (params.field !== 'customer') {
      history.push(`/orders/edit/${params.row.id}`);
    }
  };

  return (
    <Grid className={classes.container} item xs={12}>
      <DataGrid
        onCellClick={onCellClick}
        columns={columns}
        rows={transactions
          .filter(t => t)
          .map(item => ({
            ...item,
            customerName: `${item.customerObj?.firstName} ${item.customerObj?.lastName}`,
            type: item.order.fulfillmentOption
              ? item.order.fulfillmentOption.type == 'inperson'
                ? 'In Person'
                : capitalize(item.order.fulfillmentOption.type)
              : '',
            total: item.totals?.total,
          }))}
        sortModel={[{ field: 'id', sort: 'desc' }]}
        sortingOrder={['desc', 'asc', null]}
        rowHeight={72}
        pageSize={20}
        rowCount={total}
        onPageChange={retrieveOrders}
        page={page}
      />
    </Grid>
  );
};

export default OrdersList;
