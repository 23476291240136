import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export interface Color {
  primary: React.CSSProperties['color'];
  light?: React.CSSProperties['color'];
  dark?: React.CSSProperties['color'];
}

export interface ColorV2 {
  0?: React.CSSProperties['color'];
  50: React.CSSProperties['color'];
  100?: React.CSSProperties['color'];
  200: React.CSSProperties['color'];
  300?: React.CSSProperties['color'];
  400?: React.CSSProperties['color'];
  500?: React.CSSProperties['color'];
  600?: React.CSSProperties['color'];
  700?: React.CSSProperties['color'];
  800?: React.CSSProperties['color'];
  900?: React.CSSProperties['color'];
}

export interface Grays {
  800: React.CSSProperties['color'];
  700: React.CSSProperties['color'];
  600: React.CSSProperties['color'];
  500: React.CSSProperties['color'];
  400: React.CSSProperties['color'];
  300: React.CSSProperties['color'];
  200: React.CSSProperties['color'];
  100: React.CSSProperties['color'];
}

export const Colors = {
  gray: {
    800: '#1F1F1F',
    700: '#525252',
    600: '#8F8F8F',
    500: '#B8B8B8',
    400: '#E0E0E0',
    300: '#F0F0F0',
    200: '#F8F8F8',
    100: '#FFFFFF',
  },
  green: {
    primary: '#008000',
    light: '#E5FFE5',
    dark: '#006600',
  },
  red: {
    primary: '#D51A1A',
    light: '#FCE8E8',
    dark: '#A81515',
  },
  blue: {
    primary: '#005FFF',
    light: '#E5EFFF',
    dark: '#004CCC',
  },
  orange: {
    primary: '#FF8341',
    light: '#FFEEE5',
    dark: '#CC4700',
  },
  purple: {
    primary: '#7454C9',
    light: '#EFECF9',
    dark: '#4E319B',
  },
  yellow: {
    primary: '#FFDA26',
    light: '#FFF6CC',
    dark: '#806A00',
    900: '#F57F17',
  },
};

export const ColorsV2 = {
  gray: {
    0: '#FFFFFF',
    50: '#FAFAFA',
    100: '#F4F4F5',
    200: '#E4E4E7',
    300: '#D4D4D8',
    400: '#A1A1AA',
    500: '#71717A',
    600: '#52525B',
    700: '#3F3F46',
    800: '#27272A',
    900: '#18181B',
  },
  blue: {
    50: '#F5F9FF',
    100: '#E0ECFF',
    200: '#ADCCFF',
    300: '#7AACFF',
    400: '#478CFF',
    500: '#005FFF',
    600: '#0055E5',
    700: '#0048C2',
    800: '#003999',
    900: '#003080',
  },
  red: {
    50: '#FFEBEE',
    100: '#FFCDD2',
    200: '#EF9A9A',
    300: '#E57373',
    400: '#EF5350',
    500: '#F44336',
    600: '#E53935',
    700: '#D32F2F',
    800: '#C62828',
    900: '#B71C1C',
  },
  yellow: {
    50: '#FFFDE7',
    100: '#FFF9C4',
    200: '#FFF59D',
    300: '#FFF176',
    400: '#FFEE58',
    500: '#FFEB3B',
    600: '#FDD835',
    700: '#FBC02D',
    800: '#F9A825',
    900: '#F57F17',
  },
  green: {
    50: '#E8F5E9',
    100: '#C8E6C9',
    200: '#A5D6A7',
    300: '#81C784',
    400: '#66BB6A',
    500: '#4CAF50',
    600: '#43A047',
    700: '#388E3C',
    800: '#2E7D32',
    900: '#1B5E20',
  },
  pink: {
    50: '#FCE4EC',
    100: '#F8BBD0',
    200: '#F48FB1',
    300: '#F06292',
    400: '#EC407A',
    500: '#E91E63',
    600: '#D81B60',
    700: '#C2185B',
    800: '#AD1457',
    900: '#880E4F',
  },
  deepPurple: {
    50: '#EDE7F6',
    100: '#D1C4E9',
    200: '#B39DDB',
    300: '#9575CD',
    400: '#7E57C2',
    500: '#673AB7',
    600: '#5E35B1',
    700: '#512DA8',
    800: '#4527A0',
    900: '#311B92',
  },
  indigo: {
    50: '#E8EAF6',
    100: '#C5CAE9',
    200: '#9FA8DA',
    300: '#7986CB',
    400: '#5C6BC0',
    500: '#3F51B5',
    600: '#3949AB',
    700: '#303F9F',
    800: '#283593',
    900: '#1A237E',
  },
  lightBlue: {
    50: '#E1F5FE',
    100: '#B3E5FC',
    200: '#81D4FA',
    300: '#4FC3F7',
    400: '#29B6F6',
    500: '#03A9F4',
    600: '#039BE5',
    700: '#0288D1',
    800: '#0277BD',
    900: '#01579B',
  },
  cyan: {
    50: '#E0F7FA',
    100: '#B2EBF2',
    200: '#80DEEA',
    300: '#4DD0E1',
    400: '#26C6DA',
    500: '#00BCD4',
    600: '#00ACC1',
    700: '#0097A7',
    800: '#00838F',
    900: '#006064',
  },
  teal: {
    50: '#E0F2F1',
    100: '#B2DFDB',
    200: '#80CBC4',
    300: '#4DB6AC',
    400: '#26A69A',
    500: '#009688',
    600: '#00897B',
    700: '#00796B',
    800: '#00695C',
    900: '#004D40',
  },
  lightGreen: {
    50: '#F1F8E9',
    100: '#DCEDC8',
    200: '#C5E1A5',
    300: '#AED581',
    400: '#9CCC65',
    500: '#8BC34A',
    600: '#7CB342',
    700: '#689F38',
    800: '#558B2F',
    900: '#33691E',
  },
  lime: {
    50: '#F9FBE7',
    100: '#F0F4C3',
    200: '#E6EE9C',
    300: '#DCE775',
    400: '#D4E157',
    500: '#CDDC39',
    600: '#C0CA33',
    700: '#AFB42B',
    800: '#9E9D24',
    900: '#827717',
  },
  deepOrange: {
    50: '#FBE9E7',
    100: '#FFCCBC',
    200: '#FFAB91',
    300: '#FF8A65',
    400: '#FF7043',
    500: '#FF5722',
    600: '#F4511E',
    700: '#E64A19',
    800: '#D84315',
    900: '#BF360C',
  },
  warmWhite: {
    50: '#F9F5EE',
    200: '#F0E4D0',
  },
  lightRed: {
    50: '#FBE9E9',
    100: '#F7D4D4',
    200: '#F3BEBE',
    300: '#EFA9A9',
    400: '#EB9393',
    500: '#E57373',
    600: '#DD4646',
    700: '#CA2626',
    800: '#9F1E1E',
    900: '#741616',
  },
};

declare module '@material-ui/core/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    subtitle4: true;
    body3: true;
    body4: true;
    placeholder1: true;
    placeholder2: true;
    button2: true;
    caption1: true;
    caption2: true;
    caption3: true;
    caption4: true;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    placeholder1: React.CSSProperties;
    placeholder2: React.CSSProperties;
    button2: React.CSSProperties;
    caption2: React.CSSProperties;
    caption3: React.CSSProperties;
    caption4: React.CSSProperties;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    placeholder1: React.CSSProperties;
    placeholder2: React.CSSProperties;
    button2: React.CSSProperties;
    caption2: React.CSSProperties;
    caption3: React.CSSProperties;
    caption4: React.CSSProperties;
  }
}

export interface ExtendedTypographyOptions extends TypographyOptions {
  subtitle3: React.CSSProperties;
  subtitle4: React.CSSProperties;
  body3: React.CSSProperties;
  body4: React.CSSProperties;
  placeholder1: React.CSSProperties;
  placeholder2: React.CSSProperties;
  button2: React.CSSProperties;
  caption1: React.CSSProperties;
  caption2: React.CSSProperties;
  caption3: React.CSSProperties;
  caption4: React.CSSProperties;
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    branding: {
      castiron: React.CSSProperties['color'];
      seasoned: React.CSSProperties['color'];
      damascus: React.CSSProperties['color'];
      ghostWhite: React.CSSProperties['color'];
      graphite: React.CSSProperties['color'];
      iron: React.CSSProperties['color'];
      salt: React.CSSProperties['color'];
      cocoa: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
      mint: React.CSSProperties['color'];
      blueberry: React.CSSProperties['color'];
      orangepeel: React.CSSProperties['color'];
      supergreen: React.CSSProperties['color'];
      radish: React.CSSProperties['color'];
      lemonzest: React.CSSProperties['color'];
      peach: React.CSSProperties['color'];
      link: React.CSSProperties['color'];
      callout: React.CSSProperties['color'];
      error: React.CSSProperties['color'];
      elderberry: React.CSSProperties['color'];
      shadow: React.CSSProperties['color'];
      blueLink: React.CSSProperties['color'];
      /* For quote statuses:
       * I think there is probably a better way to both organize and name these, but this will do for now
       */
      lightBlue: React.CSSProperties['color'];
      lightOrange: React.CSSProperties['color'];
      lightRed: React.CSSProperties['color'];
      lightYellow: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];

      // New standardized color approach
      blue: Color;
      green: Color;
      orange: Color;
      yellow: Color;
      red: Color;
      purple: Color;
      gray: Grays;
      v2: {
        //gray is same format as colorv2
        gray: ColorV2;
        blue: ColorV2;
        red: ColorV2;
        yellow: ColorV2;
        green: ColorV2;
        pink: ColorV2;
        deepPurple: ColorV2;
        indigo: ColorV2;
        lightBlue: ColorV2;
        cyan: ColorV2;
        teal: ColorV2;
        lightGreen: ColorV2;
        lime: ColorV2;
        deepOrange: ColorV2;
        warmWhite: ColorV2;
        lightRed: ColorV2;
      };
    };
  }
  interface ThemeOptions {
    branding: {
      castiron: React.CSSProperties['color'];
      seasoned: React.CSSProperties['color'];
      damascus: React.CSSProperties['color'];
      ghostWhite: React.CSSProperties['color'];
      graphite: React.CSSProperties['color'];
      iron: React.CSSProperties['color'];
      salt: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
      cocoa: React.CSSProperties['color'];
      mint: React.CSSProperties['color'];
      blueberry: React.CSSProperties['color'];
      orangepeel: React.CSSProperties['color'];
      supergreen: React.CSSProperties['color'];
      radish: React.CSSProperties['color'];
      lemonzest: React.CSSProperties['color'];
      peach: React.CSSProperties['color'];
      link: React.CSSProperties['color'];
      callout: React.CSSProperties['color'];
      error: React.CSSProperties['color'];
      elderberry: React.CSSProperties['color'];
      shadow: React.CSSProperties['color'];
      blueLink: React.CSSProperties['color'];
      /* For quote statuses:
       * I think there is probably a better way to both organize and name these, but this will do for now
       */
      lightBlue: React.CSSProperties['color'];
      lightOrange: React.CSSProperties['color'];
      lightRed: React.CSSProperties['color'];
      lightYellow: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];

      // New standardized color approach
      blue: Color;
      green: Color;
      orange: Color;
      yellow: Color;
      red: Color;
      purple: Color;
      gray: Grays;
      v2: {
        //gray is same format as colorv2
        gray: ColorV2;
        blue: ColorV2;
        red: ColorV2;
        yellow: ColorV2;
        green: ColorV2;
        pink: ColorV2;
        deepPurple: ColorV2;
        indigo: ColorV2;
        lightBlue: ColorV2;
        cyan: ColorV2;
        teal: ColorV2;
        lightGreen: ColorV2;
        lime: ColorV2;
        deepOrange: ColorV2;
        warmWhite: ColorV2;
        lightRed: ColorV2;
      };
    };
  }
}

const defaultTheme = createMuiTheme();

//exporting to enable customization of global theme within client-admin or client-external
export const appTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1023,
      lg: 1200,
      // @ts-ignore
      xl: 1920, // HACK: This must be ignored because the type package doesn't match:
      // SIMILIAR ISSUE: https://github.com/vuetifyjs/vuetify/issues/11786
      //xxl:
    },
  },
  branding: {
    // Named Colors are deprecated in favor of the standardized pallet
    castiron: Colors.gray[800],
    seasoned: Colors.gray[800],
    damascus: Colors.gray[700],
    ghostWhite: Colors.gray[200],
    graphite: Colors.gray[600],
    iron: Colors.gray[700],
    salt: Colors.gray[200],
    darkGray: Colors.gray[600],
    cocoa: Colors.orange.light,
    mint: Colors.green.primary,
    blueberry: Colors.blue.primary,
    orangepeel: Colors.orange.primary,
    supergreen: Colors.green.primary,
    radish: Colors.red.dark,
    lemonzest: Colors.yellow.primary,
    peach: '#ffb592',
    link: Colors.blue.primary,
    callout: Colors.orange.primary,
    error: Colors.red.primary,
    elderberry: Colors.blue.primary,
    shadow: Colors.gray[300],
    blueLink: '#1717A4',
    /* For quote statuses:
     * I think there is probably a better way to both organize and name these, but this will do for now
     */
    lightBlue: '#E1F6FD',
    lightOrange: '#FDE3D2',
    lightRed: '#F7D5D5',
    lightYellow: '#FEF4C4',
    lightGreen: '#D2F8D8',

    // New standardized color approach
    ...Colors,
    v2: {
      ...ColorsV2,
    },
  },
  palette: {
    primary: {
      main: '#275bee',
    },
    secondary: {
      main: '#004e8e',
    },
    success: {
      main: '#275bee',
      light: '#CDE5C8',
      dark: '#266617',
    },
    warning: {
      main: '#ffaf00',
      light: '#FFEEC6',
      dark: '#776000',
    },
    error: {
      main: '#ff2b26',
      light: '#E7B3B3',
      dark: '#833838',
    },
    text: {
      secondary: '#868686',
    },
    action: { selected: '#F8F8F9' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: '36px',
      fontWeight: 800,
      lineHeight: '40px',
      color: Colors.gray[800],
    },
    h2: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '30px',
      color: Colors.gray[800],
    },
    h3: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '26px',
      color: Colors.gray[800],
    },
    h4: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '26px',
      color: Colors.gray[800],
    },
    h5: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[800],
    },
    h6: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16px',
      color: Colors.gray[800],
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[800],
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[800],
    },
    subtitle3: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[700],
    },
    subtitle4: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[700],
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[800],
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      color: Colors.gray[800],
    },
    body3: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[700],
    },
    body4: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[700],
    },
    placeholder1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[500],
    },
    placeholder2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[500],
    },
    button: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      color: Colors.gray[800],
      textTransform: 'none',
    },
    button2: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: Colors.gray[800],
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: Colors.gray[800],
    },
    caption2: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: Colors.green.primary,
    },
    caption3: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: Colors.red.primary,
    },
    caption4: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      color: ColorsV2.gray[700],
    },
  } as ExtendedTypographyOptions,
  overrides: {
    // Style sheet name ⚛️
    MuiFormControlLabel: {
      root: {
        '& .MuiCheckbox-root': {
          color: '#dadae9',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
          color: '#275bee',
        },
      },
      label: {
        fontSize: 14,
        color: Colors.gray[800],
        fontWeight: 600,
        paddingRight: 30,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: '#FC6E26',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: '#E6E6EA',
      },
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiSlider: {
      thumb: {
        color: '#05ba89',
      },
      track: {
        color: '#05ba89',
      },
      rail: {
        color: Colors.gray[600],
      },
    },
    MuiTableHead: {
      root: {
        '& .MuiTableCell-head': {
          textTransform: 'uppercase',
          fontSize: 12,
          color: '#656579',
          fontWeight: 900,
          marginRight: 8,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${Colors.gray[400]}`,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '12px',
      },
      notchedOutline: {
        borderColor: Colors.gray[400],
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      root: {
        borderRadius: '12px',
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
      },
      sizeLarge: {
        paddingLeft: defaultTheme.spacing(4),
        paddingRight: defaultTheme.spacing(4),
        /* I would like to use spacing here,
         * but spacing is in increments of 8, and 1.375 seems weird
         */
        paddingTop: '11px',
        paddingBottom: '11px',
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        minWidth: defaultTheme.spacing(4),
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '12px',
      },
    },
    MuiTabs: {
      root: {
        '& .MuiTabs-flexContainer': {
          boxSizing: 'border-box',
          borderBottom: '2px solid #E6E6EA',
        },
      },
    },
    MuiTab: {
      root: {
        opacity: 1,
        fontSize: 16,
        fontWeight: 700,
        color: '#0D0C21',
        '&.Mui-selected': {
          color: '#275bee',
        },
        '&.MuiTab-textColorInherit': {
          opacity: 1,
        },
      },
    },
  },
  props: {
    MuiButton: {
      size: 'large',
    },
    MuiFilledInput: {
      margin: 'none',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiListItem: {
      dense: true,
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    MuiToolbar: {
      variant: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiOutlinedInput: {
      margin: 'none',
    },
    MuiTextField: {
      margin: 'dense',
    },
  },
});

type Props = {
  children: any;
};

const Theme: React.FC<Props> = (props: Props) => {
  return <ThemeProvider theme={appTheme}>{props.children}</ThemeProvider>;
};

export default Theme;
