import React from "react";
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import { Link } from 'react-router-dom';
import Chip from '../Chip';
import Typography from '../Typography';

type Props = {
    variant?: 'small' | 'normal';
    feature?: string;
    selected?: boolean;
    hasTooltip?: boolean;
    hasUpgradeLink?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
    proFeatureChip: {
        backgroundColor: theme.branding.blue.light,
        color: theme.branding.blue.primary,
        display: 'flex',
        alignItems: 'center',
        padding: '2px 4px',
    },
    proText: {
        color: theme.branding.blue.primary,
    },
    selectedChip: {
        border: `1px solid ${theme.branding.blue.primary}`,
        padding: '1px 3px'
    },
    starIcon: {
        height: 14,
        width: 14,
        marginRight: props => props.variant === 'small' ? 0 : 5,
    },
    tooltip: {
        borderRadius: 12,
        fontSize: 12,
        maxWidth: 220,
        padding: 8,
    },
    upgradeLink: {
        color: theme.branding.gray[100],
        textDecoration: 'underline',
        '&:hover': {
            color: theme.branding.gray[100],
            fontWeight: 700
        }
    },
}))


const ProFeatureChip: React.FC<Props> = (props: Props) => {
    const { variant = 'normal', selected, feature, hasTooltip, hasUpgradeLink } = props;

    const classes = useStyles(props);

    return (
        <>
            {hasTooltip ?
                <Tooltip
                    arrow
                    classes={{
                        tooltip: classes.tooltip
                    }}
                    PopperProps={{
                        style: {
                            marginTop: '-12px',
                        }
                    }}
                    title={`${hasUpgradeLink ? <Link to='/store/plans' className={classes.upgradeLink}>Upgrade to Pro</Link> : 'Upgrade to Pro'} to unlock ${feature} and many other features.`}
                    interactive={hasUpgradeLink}
                >
                    <span>
                        <Chip className={clsx([selected && classes.selectedChip, classes.proFeatureChip])}>
                            <StarBorderOutlinedIcon className={classes.starIcon} />
                            {
                                variant !== 'small' &&
                                <Typography variant='h6' className={classes.proText}>PRO</Typography>
                            }
                        </Chip>
                    </span>
                </Tooltip>
                :
                <span>
                    <Chip className={clsx([selected && classes.selectedChip, classes.proFeatureChip])}>
                        <StarBorderOutlinedIcon className={classes.starIcon} />
                        {
                            variant !== 'small' &&
                            <Typography variant='h6' className={classes.proText}>PRO</Typography>
                        }
                    </Chip>
                </span>}
        </>
    );
};

export default ProFeatureChip;