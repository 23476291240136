import { Grid, Theme, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '../Typography';
import SvgIcon from '../SvgIcon';
import InfoIcon from '../assets/info.svg';
import { Shop } from '@castiron/domain';

type Props = {
  content: string;
  shop: Shop;
  infoIcon?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  afterPurchaseDetailsContainer: {
    backgroundColor: theme.palette.grey[100],
    padding: 16,
    borderRadius: 8,
  },
  infoIcon: {
    width: 37,
    height: 37,
  },
  text: {
    whiteSpace: 'pre-line',
    position: 'relative',
  },
}));

const AfterPurchaseDetails: React.FC<Props> = (props: Props) => {
  const { content, shop, infoIcon } = props;
  const classes = useStyles();
  const theme = useTheme();

  const shopColor = shop?.config?.shopTheme?.shopButtonColor || 'blue';

  if (!content) return null;

  return (
    <Grid className={classes.afterPurchaseDetailsContainer}>
      <Typography variant="body1" className={classes.text}>
        {infoIcon && (
          <SvgIcon className={classes.infoIcon} style={{ color: theme.branding.v2[shopColor][500] }}>
            <InfoIcon />
          </SvgIcon>
        )}
        {content}
      </Typography>
    </Grid>
  );
};

export default AfterPurchaseDetails;
