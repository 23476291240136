import Checkbox from '../Checkbox';
import TextInput from '../Inputs/TextInput';
import Typography from '../Typography';
import {
  BaseProduct,
  CustomProduct,
  FeatureName,
  InputField,
  SelectedAsset,
  SelectedFieldValue,
  SelectedInputFields,
  Shop,
} from '@castiron/domain';
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dinero from 'dinero.js';
import { Field, FormikProps, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import PhotoVariation from './PhotoVariation';

type Props = {
  product: BaseProduct;
  variation: InputField;
  index: number;
  onChange?: (variation: InputField, selectedValues: SelectedFieldValue[], event, customText?: string) => void;
  onPhotoVarChange?: (variation: InputField, selectedValues: SelectedAsset[]) => void;
  uploadingStatus?: (uploading: boolean, selectedValues?: SelectedAsset[], numImages?: number) => void;
  shop?: Shop;
  isEditMode?: boolean;
  readonly?: boolean;
  showErrors?: boolean;
  features?: FeatureName[];
  removeTopDivider?: boolean;
  sectionRoundness?: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    marginBottom: 16,
  },
  divider: {
    background: theme.branding.v2.gray[200],
  },
  dividerBottom: {
    background: theme.branding.v2.gray[100],
    borderTop: 0,
    height: '8px !important',
    opacity: '100 !important',
    /* To get around default padding from the Card */
    margin: '0px -24px',
    width: 'calc(100% + 48px)',
  },
  dividerTop: {
    background: theme.branding.v2.gray[100],
    borderTop: 0,
    height: '8px !important',
    opacity: '100 !important',
    marginBottom: 24,
    /* To get around default padding from the Card */
    margin: '0px -24px',
    width: 'calc(100% + 48px)',
  },
  image: {
    borderRadius: props => props.sectionRoundness,
    height: '100%',
    marginBottom: 16,
    maxHeight: 520,
    maxWidth: 456,
    width: '100%',
  },
  option: {
    paddingBottom: 16,
  },
  pill: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 8,
    padding: '4px 8px',
    width: 'fit-content',
  },
  radio: {
    marginTop: -16,
  },
  required: {
    background: theme.branding.v2.gray[100],
    border: `1px solid ${theme.branding.v2.gray[100]}`,
  },
  requiredError: {
    background: theme.branding.v2.red[50],
    border: 'none',
    color: theme.branding.v2.red[500],
  },
  select: {
    borderRadius: 12,
    height: 48,
    margin: '16px 0px',
    width: '100%',
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'break-spaces',
    },
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectOptionContainer: {
    whiteSpace: 'break-spaces',
    '&:hover': {
      background: '#E6E6EA4D',
    },
    '&.Mui-selected': {
      background: '#E6E6EA4D',
    },
  },
  variationGroup: {
    '& .MuiFormControlLabel-label': {
      width: '100%',
      paddingRight: 0,
    },

    '& .MuiFormControlLabel-root': {
      marginRight: 0,
      paddingTop: 16,
    },
  },
}));

const ProductVariations: React.FC<Props> = (props: Props) => {
  const {
    index,
    variation,
    onChange,
    product,
    onPhotoVarChange,
    uploadingStatus,
    shop,
    isEditMode = false,
    readonly = false,
    showErrors = false,
    features,
    removeTopDivider = false,
    sectionRoundness = '12px',
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { values, setFieldValue }: any = useFormikContext();

  const handleChange = (variation: InputField, event, customText?: string, valueIndex?: number) => {
    if (!isEditMode) {
      const valueId = customText ? event : event.target ? event.target.value : '';
      const selectedValueIndex = variation.values.findIndex(val => val.id === valueId);
      const selectedValue = variation.values[selectedValueIndex];

      if (valueId === 'none') {
        setFieldValue(`selectedFields[${index}]`, undefined);
      } else {
        setFieldValue(`selectedFields[${index}].inputFieldId`, variation.id);
        setFieldValue(`selectedFields[${index}].inputFieldName`, variation.name);
        setFieldValue(`selectedFields[${index}].required`, variation.required);
        setFieldValue(`selectedFields[${index}].type`, variation.type);

        setFieldValue(`selectedFields[${index}].selectedValues`, [selectedValue]);
        if (customText) setFieldValue(`selectedFields[${index}].selectedValues[${valueIndex}].name`, customText);
      }

      if (valueId || variation.type === 'text') onChange && onChange(variation, [selectedValue], event, customText);
    }
  };

  const handleCheckboxChange = (event, variation: InputField, value: SelectedFieldValue, valueIndex: number) => {
    if (!isEditMode) {
      setFieldValue(`selectedFields[${index}].inputFieldId`, variation.id);
      setFieldValue(`selectedFields[${index}].inputFieldName`, variation.name);
      setFieldValue(`selectedFields[${index}].required`, variation.required);
      setFieldValue(`selectedFields[${index}].type`, variation.type);
      let selectedValues = [];
      if (values.selectedFields[index]) {
        if (!values.selectedFields[index].selectedValues.some(val => val.id === value.id)) {
          selectedValues = [...values.selectedFields[index].selectedValues, value];
          setFieldValue(`selectedFields[${index}].selectedValues`, selectedValues);
        } else {
          const removedCheckbox = values.selectedFields[index].selectedValues.findIndex(val => val.id === value.id);
          values.selectedFields[index].selectedValues.splice(removedCheckbox, 1);
          selectedValues = values.selectedFields[index].selectedValues;
        }
      } else {
        selectedValues = [value];
        setFieldValue(`selectedFields[${index}].selectedValues`, selectedValues);
      }

      onChange && onChange(variation, selectedValues, event);
    }
  };

  const optionalOrRequiredPill = (
    <Typography
      className={`${classes.pill} ${
        showErrors && variation?.required ? classes.requiredError : variation?.required ? classes.required : ''
      }`}
      variant="caption"
    >
      {variation?.required ? 'Required' : 'Optional'}
    </Typography>
  );

  const variationLabel = variation.type !== 'photo' && (
    <Grid container direction="column">
      <Grid alignItems="flex-start" container item justify="space-between" wrap={isMobile ? 'wrap' : 'nowrap'}>
        <Grid container item wrap="nowrap">
          <Typography variant="subtitle1">{variation.name}</Typography>
          {variation.type === 'text' && variation?.values && variation?.values[0]?.cost > 0 && (
            <Typography style={{ color: theme.branding.v2.gray[500], marginLeft: 8 }} variant="body1">
              {`+${Dinero({ amount: variation?.values[0]?.cost }).toFormat('$0.00')}`}
            </Typography>
          )}
        </Grid>
        <Grid item>{optionalOrRequiredPill}</Grid>
      </Grid>
      {variation.description && (
        <Grid item>
          <Typography style={{ color: theme.branding.v2.gray[500] }} variant="body2">
            {variation.description}
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  const getOptionLabel = (option, isLastChild) => (
    <>
      <Grid alignItems="center" className={classes.option} container item justify="space-between">
        <Typography variant="body1">{option.name}</Typography>
        <Typography style={{ color: theme.branding.v2.gray[500] }} variant="body1">
          {option.cost ? `+${Dinero({ amount: option.cost }).toFormat('$0.00')}` : ''}
        </Typography>
      </Grid>
      {!isLastChild && <Divider className={classes.divider} />}
    </>
  );

  const showVariationPhoto = features?.includes('admin.products.variations.photo') && variation?.imageObj?.downloadUrl;
  const isFirstVariation = index === 0;
  const isLastVariation = index === product.variations?.length - 1;

  return (
    <Grid className={classes.container} container direction="column" item wrap="nowrap">
      {showVariationPhoto && !isFirstVariation && !removeTopDivider && <hr className={classes.dividerTop} />}
      {showVariationPhoto && (
        <Grid container justify="center" alignItems="center">
          <img className={classes.image} src={variation?.imageObj?.downloadUrl} />
        </Grid>
      )}
      {variationLabel}
      {variation.type === 'select' &&
        (variation.required ? (
          <>
            <RadioGroup
              aria-label={variation.name}
              className={classes.variationGroup}
              name={variation.name}
              onChange={event => handleChange(variation, event)}
            >
              {variation.values.map((option, index) => (
                <FormControlLabel
                  key={option.id}
                  value={option.name}
                  control={<Radio className={classes.radio} value={option.id} size="small" color="primary" />}
                  label={getOptionLabel(option, index === variation.values.length - 1)}
                />
              ))}
            </RadioGroup>
          </>
        ) : (
          <Select
            className={classes.select}
            displayEmpty={true}
            value={
              values.selectedFields
                ? values.selectedFields[index]?.selectedValues && values.selectedFields[index]?.selectedValues[0]
                  ? values.selectedFields[index].selectedValues[0].id
                  : ''
                : ''
            }
            onChange={event => handleChange(variation, event)}
            placeholder="Select only one option"
            variant="outlined"
          >
            <MenuItem className={classes.selectOptionContainer} value="none">
              <em>None</em>
            </MenuItem>
            {variation.values.map(option => (
              <MenuItem className={classes.selectOptionContainer} key={option.id} value={option.id}>
                {option.cost ? (
                  <Typography className={classes.selectOption} variant="body1">
                    {option.name}{' '}
                    <span style={{ fontWeight: 'bold' }}>+{Dinero({ amount: option.cost }).toFormat('$0.00')}</span>
                  </Typography>
                ) : (
                  <Typography className={classes.selectOption} variant="body1">
                    {option.name}
                  </Typography>
                )}
              </MenuItem>
            ))}
          </Select>
        ))}

      {variation.type === 'multiselect' && (
        <FormGroup className={classes.variationGroup}>
          {variation.values.map((option, index: number) => (
            <Field
              key={option.id}
              as={Checkbox}
              id={option.id}
              onChange={event => handleCheckboxChange(event, variation, option, index)}
              name={option.name}
              label={getOptionLabel(option, index === variation.values.length - 1)}
            />
          ))}
        </FormGroup>
      )}

      {variation.type === 'text' && (
        <>
          {variation.values.map((customVal, i: number) => (
            <div key={customVal.id} style={{ padding: '16px 0px' }}>
              <TextInput
                name={`values.selectedFields[${index}]?.selectedValues[${i}]?.name`}
                onChange={event => handleChange(variation, customVal.id, event.target.value, i)}
                placeholder={customVal.name}
              />
            </div>
          ))}
        </>
      )}

      {variation.type === 'photo' && (
        <>
          <PhotoVariation
            headerVariant="subtitle1"
            uploadingStatus={uploadingStatus}
            onPhotoVarChange={onPhotoVarChange}
            product={product as CustomProduct}
            variation={variation}
            shop={shop}
            index={index}
            readonly={readonly}
            showErrors={showErrors}
          />
        </>
      )}
      {showVariationPhoto && !isLastVariation && <hr className={classes.dividerBottom} />}
    </Grid>
  );
};

export default ProductVariations;
