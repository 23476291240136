import React from 'react';
import CancelOrderModal from '../CancelOrderModal';
import ContactModal from '../ContactModal';
import DeleteModal from '../DeleteModal';
import FulfillmentDateTimeModal from '../FulfillmentDateTimeModal';
import ResetPasswordModal from '../ResetPasswordModal';
import NoteModal from '../NoteModal';
import SimpleAlert from '../SimpleAlert';
import SimpleAlertV2 from '../SimpleAlert/SimpleAlertV2';
import SimpleActionAlert from '../SimpleAlert/SimpleActionAlert';
import FulfillOrderModal from '../Orders/OrderFulfilledModal';
import CustomOrderRejectModal from '../Orders/CustomOrders/CustomOrderRejectModal';
import AddProductModal from '../Products/AddProductModal/AddProductModal';
import { ExportOrdersModal } from '../Orders/ExportOrdersModal';
import BulkContactModal from '../BulkContactModal';
import QuoteSendModal from '../Quotes/QuoteSendModal';
import QuoteSentModal from '../Quotes/QuoteSentModal';
import ProductPreviewModal from '../Products/PreviewModals/ProductPreviewModal';
import CustomProductPreviewModal from '../Products/PreviewModals/CustomProductPreviewModal';
import ExportContactListModal from '../Customers/ExportContactListModal';
import AddFulfillmentModal from '../Store/Fulfillment/AddFulfillmentModal/AddFullfillmentModal';
import PaymentModal from '../Store/Payments/PaymentModal';
import ChangePaymentModal from '../Store/SubscriptionPlans/ChangePaymentModal';
import GeneralModal from './GeneralModal';
import AvailabilityModal from '../Calendar/AvailabilityModal';
import OnboardingModal from '../Onboarding/OnboardingModal';
import AddOrderModal from '../Orders/AddOrderModal';
import SelectPlanModal from '../SelectPlan/Modal/SelectPlanModal';
import DomainManagementModal from '../Store/Domains/ManagementModal';
import CancelPlanModal from '../Store/SubscriptionPlans/CancelPlan/CancelPlanModal';
import CancelSuccessModal from '../Store/SubscriptionPlans/CancelPlan/CancelSuccessModal';
import ResumeSubscriptionModal from '../Store/SubscriptionPlans/ResumeSubscriptionModal';
import BusinessCTAModal from '../SelectPlan/BusinessCTAModal/BusinessCTAModal';
import IndividualListingModal from '../Calendar/Listing/IndividualListingModal';
import SeoLocationModal from '../SeoLocationModal';
import PrelaunchModals from '../Home/PrelaunchModals';
import AddDomainModal from '../Store/Domains/ConnectDomainFlow/AddDomainModal';
import VerifyDomainModal from '../Store/Domains/ConnectDomainFlow/VerifyDomainModal';
import DomainProcessingModal from '../Store/Domains/ConnectDomainFlow/DomainProcessingModal';
import ShopLiveModal from '../Home/ShopLiveModal';
import ShareLinksModal from '../Layout/ShareLinksModal';
import SMSNotificationModal from '../SMSNotificationModal';
import MarkAsPaidModal from '../Quotes/EditQuote/MarkAsPaidModal';
import EditPhotoModal from '../EditPhotoModal';
import AIGenerationModal from '../AIGenerationModal';
import BackgroundPhotoModal from '../Store/Pages/BackgroundPhotoModal';
import RedeemCouponModal from '../Store/SubscriptionPlans/RedeemCouponModal';
import LegacyGatingModal from '../LegacyGatingModal';
import ConfirmThemeModal from '../Store/Website/ConfirmThemeModal';
import FreeCustomDomainModal from '../Store/Domains/FreeCustomDomainFlow/FreeCustomDomainModal';
import StartDomainConnectModal from '../Store/Domains/StartDomainConnectModal';
import DeleteCategoryModal from '../Products/ProductCategoryModals/DeleteCategoryModal';
import RenameCategoryModal from '../Products/ProductCategoryModals/RenameCategoryModal';
import PromptStripeSetupModal from '../PromptStripeSetupModal';
import ProductSocialShareModal from '../Products/ProductSocialShareModal';
import BulkProductActionsModal from '../Products/BulkProductActionsModal';
import AutomationTest from '../Messaging/Automations/AutomationTest';
import TemplatesDisplayModal from '../Messaging/Templates/TemplatesDisplayModal';
import ManageCouponModal from '../Messaging/Automations/ManageCouponModal';
import TurnOffAutomationModal from '../Messaging/Automations/TurnOffAutomationModal';
import DeleteMarketingModal from '../Messaging/MarketingStats/DeleteMarketingModal';

const MODAL_COMPONENTS = {
  ABOUT_CASTIRON_AI_MODAL: AIGenerationModal,
  ADD_DOMAIN_MODAL: AddDomainModal,
  ADD_FULFILLMENT_MODAL: AddFulfillmentModal,
  ADD_ORDER_MODAL: AddOrderModal,
  ADD_PRODUCT_MODAL: AddProductModal,
  AVAILABILITY_MODAL: AvailabilityModal,
  BACKGROUND_PHOTO_MODAL: BackgroundPhotoModal,
  BUSINESS_CTA_MODAL: BusinessCTAModal,
  BULK_CONTACT_MODAL: BulkContactModal,
  BULK_PRODUCT_ACTIONS_MODAL: BulkProductActionsModal,
  CANCEL_PLAN_MODAL: CancelPlanModal,
  CANCEL_ORDER_MODAL: CancelOrderModal,
  CANCEL_SUCCESS_MODAL: CancelSuccessModal,
  CHANGE_PLANS_MODAL: SelectPlanModal,
  CHANGE_PAYMENT_MODAL: ChangePaymentModal,
  CONFIRM_THEME_MODAL: ConfirmThemeModal,
  CONTACT_MODAL: ContactModal,
  CUSTOM_PRODUCT_MODAL: CustomProductPreviewModal,
  DELETE_MODAL: DeleteModal,
  DOMAIN_PROCESSING_MODAL: DomainProcessingModal,
  DOMAIN_MANAGEMENT_MODAL: DomainManagementModal,
  EDIT_PHOTO_MODAL: EditPhotoModal,
  EXPORT_CONTACT_LIST_MODAL: ExportContactListModal,
  EXPORT_ORDERS: ExportOrdersModal,
  FREE_CUSTOM_DOMAIN_MODAL: FreeCustomDomainModal,
  FULFILL_ORDER: FulfillOrderModal,
  FULFILLMENT_DATE_TIME: FulfillmentDateTimeModal,
  GENERAL_MODAL: GeneralModal,
  INDIVIDUAL_LISTING_MODAL: IndividualListingModal,
  LEGACY_GATING_MODAL: LegacyGatingModal,
  MARK_AS_PAID_MODAL: MarkAsPaidModal,
  NOTE_MODAL: NoteModal,
  ONBOARDING_MODAL: OnboardingModal,
  PAYMENT_MODAL: PaymentModal,
  PRODUCT_MODAL: ProductPreviewModal,
  PRELAUNCH_MODALS: PrelaunchModals,
  PROMPT_STRIPE_SETUP_MODAL: PromptStripeSetupModal,
  QUOTE_SEND_MODAL: QuoteSendModal,
  QUOTE_SENT_MODAL: QuoteSentModal,
  REJECT_CUSTOM_ORDER: CustomOrderRejectModal,
  RESUME_SUBSCRIPTION_MODAL: ResumeSubscriptionModal,
  RESET_PASSWORD_MODAL: ResetPasswordModal,
  SEO_LOCATION_MODAL: SeoLocationModal,
  SHOP_LIVE_MODAL: ShopLiveModal,
  SIMPLE_ALERT: SimpleAlert,
  SIMPLE_ALERT_V2: SimpleAlertV2,
  SIMPLE_ACTION_ALERT: SimpleActionAlert,
  START_DOMAIN_CONNECT_MODAL: StartDomainConnectModal,
  SHARE_LINKS_MODAL: ShareLinksModal,
  REDEEM_COUPON_MODAL: RedeemCouponModal,
  SMS_NOTIFICATION_OPTIN_MODAL: SMSNotificationModal,
  VERIFY_DOMAIN_MODAL: VerifyDomainModal,
  DELETE_CATEGORY_MODAL: DeleteCategoryModal,
  RENAME_CATEGORY_MODAL: RenameCategoryModal,
  PRODUCT_SOCIAL_SHARE_MODAL: ProductSocialShareModal,
  AUTOMATION_TEST_MODAL: AutomationTest,
  MESSAGING_TEMPLATES_DISPLAY_MODAL: TemplatesDisplayModal,
  MANAGE_COUPON_MODAL: ManageCouponModal,
  TURN_OFF_AUTOMATION_MODAL: TurnOffAutomationModal,
  DELETE_MARKETING_MODAL: DeleteMarketingModal,
};

export type ModalType = keyof typeof MODAL_COMPONENTS;

type Props = {
  modalType: ModalType;
  modalProps: Record<string, any>;
};

const RootModal: React.FC<Props> = (props: Props) => {
  if (!props.modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[props.modalType];

  // @ts-ignore
  return <SpecificModal {...props.modalProps} />;
};

export default RootModal;
